import React, {useContext} from "react";
// nodejs library that concatenates classes
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-react/views/homePageSections/contactStyle.js";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import LogoCarousel from "./LogoCarousel/LogoCarousel";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailIcon from '@material-ui/icons/Mail';
import Button from "../../../components/CustomButtons/Button";
import {LanguageContext} from "../../../languages/Language";


const useStyles = makeStyles(styles);

export default function Contact({id}){
    const classes = useStyles();
    const localizedContent = useContext(LanguageContext).dictionary.contactContent;

    return(
        <div className = {classes.section} id={id}>
            <div className = {classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8} className = {classes.boxed}>
                        <h1 className = {classes.blockTitle}>
                            {localizedContent.title}
                        </h1>
                        <span className = {classes.divider}/>
                        <h4 className = {classes.description}>
                            {localizedContent.text}
                        </h4>

                        <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <LogoCarousel/>
                        </GridItem>

                        <div className={classes.linkWrapper}>
                            <ul>
                                <li>

                                <Button
                                        color="primary"
                                        size="lg"
                                        href="mailto:tuananh.bach.pro@gmail.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <MailIcon/>
                                        Email
                                    </Button>

                                </li>

                                <li>
                                    <Button
                                        color="primary"
                                        size="lg"
                                        href="https://www.linkedin.com/in/tuananh-bach/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <LinkedInIcon/>
                                        LinkedIn
                                    </Button>

                                </li>

                                <li>
                                    <Button
                                        color="primary"
                                        size="lg"
                                        href="https://github.com/Trukibouge"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <GitHubIcon/>
                                        GitHub
                                    </Button>
                                </li>
                            </ul>
                        </div>

                    </GridItem>
                </GridContainer>


            </div>
        </div>
    )
}