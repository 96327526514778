import fr from "assets/img/flags/france.svg";
import en from "assets/img/flags/united-kingdom.svg";
import jp from "assets/img/flags/japan(1).svg";
import sp from "assets/img/flags/spain.svg";
import ch from "assets/img/flags/china.svg";
import kr from "assets/img/flags/south-korea.svg";

const skillContent = {
    common:{
        languages: {
            cs:{
                name:"C#",
                level: 5,
            },
            ts:{
                name: "TypeScript",
                level: 5,
            },
            js:{
                name: "Javascript",
                level: 5,
            },
            scss:{
                name:"SASS/SCSS",
                level: 5
            },
            html:{
                name: "HTML",
                level: 5,
            },
            css:{
                name: "CSS",
                level: 5
            },
            py:{
                name:"Python",
                level: 4,
            },

            java:{
                name:"Java",
                level: 4
            },
            sql:{
                name:"SQL",
                level: 4,
            },
            kotlin:{
                name:"Kotlin",
                level: 3
            },
            cpp:{
                name: "C++",
                level: 3
            },
            c:{
                name: "C",
                level: 3
            },
            dlang:{
                name: "Dlang",
                level: 2,
            },
            vhdl:{
                name: "VHDL",
                level: 2
            },
        },

        spLanguages:{
            fr:{
                icon: fr,
                level: 5
            },
            en:{
                icon: en,
                level: 5
            },
            jp:{
                icon: jp,
                level: 4.5
            },
            kr:{
                icon: kr,
                level: 1.5
            },
            sp:{
                icon: sp,
                level: 1,
            },
            ch:{
                icon: ch,
                level: 1
            },

        }
    },
    en: {
        categoryName: "Skills",
        frameworks: ["ReactJS", "Node.js", "NestJS", ".NET (Framework & Core)", "Angular", "Jest", "Unity"],
        tools: ["JSS", "Bash", "Firebase", "Vercel", "Git", "Postman", "MS SQL Server", "IBM DB2", "MongoDB", "PostgreSQL", "WordPress", "UIPath", "Microsoft Power BI", "STM32Cube"],
        tools2: ["STM32Cube", "Proteus", "MPLabX", "ModelSim", "MatLab", "AnyLogic", "Microsoft Office Suite (including Excel and VBA)"],
        design: ["InVision", "Prototyping", "Wireframing", "User Testing"],
        process: ["Pre-sales technical consulting", "Requirements Engineering", "Test Automation", "V-Model", "Agile Scrum", "Lean Six Sigma", "Robotic Process Automation"],
        spLanguages: {
            fr: {
                title: "French",
                desc: "Native",
            },
            en: {
                title: "English",
                desc: "Fully Proficient, TOEIC (2017) 985/990"
            },
            jp: {
                title: "Japanese",
                desc: "Full Professional Proficiency, JLPT N2 (2017) 157/180"
            },
            kr:{
                title: "Korean",
                desc: "Basic Proficiency"
            },
            sp: {
                title: "Spanish",
                desc: "Basic Proficiency",
            },
            ch: {
                title: "Chinese",
                desc: "Basic Proficiency"
            },
        },
    },

    jp: {
        categoryName: "スキル一覧",
        frameworks: ["ReactJS", "Node.js", "NestJS", ".NET (Framework & Core)", "Angular", "Jest", "Unity"],
        tools: ["JSS", "Bash", "Firebase", "Vercel", "Git", "Postman", "MS SQL Server", "IBM DB2", "MongoDB", "PostgreSQL", "WordPress", "UIPath", "Microsoft Power BI", "STM32Cube"],
        tools2: ["STM32Cube", "Proteus", "ModelSim", "MatLab", "AnyLogic", "Microsoft Office Suite (エクセルやVBAを含む)"],
        design: ["InVision", "プロトタイピング", "ワイヤーフレーム作成", "ユーザーテスト"],
        process: ["プリセールスエンジニアリング", "要求工学", "単体テスト", "Vモデル", "アジャイル・スクラム", "リーンシックス・シグマ", "RPA"],
        spLanguages: {
            fr: {
                title: "フランス語",
                desc: "ネイティブ",
            },
            en: {
                title: "英語",
                desc: "バイリンガル, TOEIC (2017) 985/990"
            },
            jp: {
                title: "日本語",
                desc: "ビジネスレベル(日本在住経験　有), JLPT N2 (2017) 157/180"
            },
            kr: {
                title: "韓国語",
                desc: "通常会話程度"
            },
            sp: {
                title: "スペイン語",
                desc: "通常会話程度",
            },
            ch: {
                title: "中国語",
                desc: "通常会話程度"
            },
        },
    }
}

export {skillContent}