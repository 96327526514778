const archiveContent = {
    en: {
        title: "Project Archive",
        desc: "A non-exhaustive list of the notable projects I worked on during the last few years."
    },
    jp: {
        title: "プロジェクトアーカイブ",
        desc: "ここ数年の間に取り組んだ注目のプロジェクトの一覧"
    }
}

export default archiveContent;