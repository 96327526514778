const contactContent = {
    en: {
        title: "Contact Me",
        text: "I'm currently open for opportunities, starting mid-September 2020! I don't have any preference on location and free to move in any country. In particular, let me know if you need help with one of these technology:",
    },
    jp: {
        title: "お問い合わせ",
        text: "2020年9月中旬からお仕事を探しています！場所のこだわりはなく、どの国からのご依頼も大歓迎です。特に、以下の技術のいずれかについてのお手伝いが必要な場合は、是非ご連絡ください。"
    }
}

export default contactContent;