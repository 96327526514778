import React, {useContext} from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

import {Link} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-react/views/homePageSections/introductionStyle.js";


import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Badge from "../../../components/Badge/Badge.js";

import profile from "assets/img/faces/bach.jpg";

import {LanguageContext} from "languages/Language";

const useStyles = makeStyles(styles);

function Introduction({id}){
    const classes = useStyles();
    const content = useContext(LanguageContext).dictionary.introductionContent;

    return(
        //<div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} id={id}>
            <GridContainer justify="center" alignItems = "center">
            <GridItem xs={12} sm={12} md={6}>
                <div className={classes.blockTitle}>
                    {content.title}
                </div>

                <div className={classes.description}>
                    <p>
                        {content.firstParagraph}
                        
                    </p>
                    <p>
                        {content.secondParagraph}
                    </p>
                    <p>
                        {content.finisher}
                    </p>
                </div>

                <div className = {classes.description}>
                    <br/>
                    <h6>{content.techIntroduction}</h6>
                    <Badge color="success">React</Badge>
                    <Badge color="success">Node.js</Badge>
                    <Badge color="info">TypeScript</Badge>
                    <Badge color="info">HTML {"&"} SCSS</Badge>
                    <Badge color="info">Python</Badge>
                    <Badge color="info">Kotlin</Badge>
                    <Badge color="rose">WordPress</Badge>
                    <Badge color="rose">Vercel</Badge>
                    <Badge color="danger">PostgreSQL</Badge>
                    <Badge color="danger">MongoDB</Badge>
                    {/*<Badge color="rose">C# (.NET Framework)</Badge>*/}
                    {/*<Badge color="danger">JSS</Badge>*/}
                </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
                <div className={classes.imageWrap}>
                    <Link to="/journey">
                        <img src={profile} alt="..."
                             className={classNames(classes.imgRaised, classes.imgRounded, classes.imgFluid)}/>
                        <div className={classes.imageDescriptionLayer}>
                            <p className={classes.imageDescription}>
                                {content.learnMore}
                            </p>
                        </div>
                    </Link>
                </div>
            </GridItem>
                
            </GridContainer>
        </div>  
    )
}

export default Introduction;