// const bgPrimary = "#25274D";
// const bgSecondary = "#464866";

import * as colors from "assets/jss/material-kit-react/colors"

const style = {
    container:{
        flexDirection:"column",
        alignItems:"flex-start"
    },

    project:{
        gap: "10px",
        boxAlign: "center",
        marginBottom: "100px",
        alignItems: "center",
        wrap:"nowrap",
        textAlign: "left",
        display:"grid",
        gridGap:"10px",
        gridTemplateColumns:"repeat(12,1fr)",

        "&:last-of-type":{
            marginBottom:"0",
        },

        "&:nth-of-type(odd)":{
            textAlign:"right",

            "& $content":{
              gridColumn:"7/-1",
              textAlign:"right"
            },

            "& $techList":{
                justifyContent:"flex-end",
                "& li":{
                    marginLeft:"20px",
                    marginRight:"0",
                }
            },

            "& $linkWrapper":{
                justifyContent: "flex-end",
                marginLeft:"0",
                marginRight:"-10px"
            },

            "& $imgContainer":{
                gridColumn: "1 / 8",
            },

            "& $projectName":{
                paddingLeft: "3rem",
                paddingRight: "0",
            }
        }
    },

    content:{
        position:"relative",
        padding: "40px 40px 40px",
        gridColumn:"1/7",
        gridRow:"1/-1"
    },

    label:{
        color: "#ff7eee",
        fontSize: "13px",
        fontWeight: "normal",
        marginTop: "10px",
        paddingTop:"0",
    },

    projectName:{
        fontSize:"28px",
        margin:"0 0 20px",
        color: "rgb(204, 214, 246)",
        paddingRight: "3rem",
        lineHeight: "1"
    },

    description:{
        boxShadow: "rgba(2, 12, 27, 0.7) 0px 10px 30px -15px",
        transition: "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s",
        position: "relative",
        zIndex: "2",
        padding: "25px",
        backgroundColor: "#25274D",
        color: "rgb(168, 178, 209)",
        fontSize: "18px",
        borderRadius: "3px",
        textAlign: "left",
        "& p": {
            margin: "0"
        }
    },

    techList:{
        position: "relative",
        zIndex: "2",
        display: "flex",
        flexWrap: "wrap",
        padding: "0px",
        margin: "25px 0px 10px",
        listStyle: "outside none none",

        "& li":{
            color: colors.metalGray,
            fontSize: "13px",
            marginBottom: "7px",
            whiteSpace:"nowrap",
            marginLeft: "20px",

            "&:last-of-type":{
                marginRight:"0"
            },

            "&:first-of-type":{
                marginLeft: "0"
            }

        }
    },

    linkWrapper:{
        display: "flex",
        position:"relative",
        boxAlign:"center",
        alignItems:"center",
        marginLeft:"-10px",
        marginTop:"10px",
        color: "rgb(204, 214, 246)",

        "& a":{
            padding:"10px",
            "& svg":{
                width: "22px",
                height: "22px",
                transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
                "&:hover":{
                    transform: "scale(1.2)",
                    color: colors.lightPink
                }
            }
        }
    },

    imgContainer:{
        boxShadow: 'rgba(2, 12, 27, 0.7) 0px 10px 30px -15px',
        position: 'relative',
        zIndex: '1',
        borderRadius: '4px',
        transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
        gridColumn:"6/-1",
        gridRow:"1/-1",
        filter: "brightness(75%) grayscale(25%)",

        "&:hover, :focus":{
            // background: "transparent",
            // filter:"none",
            // "& $featuredImage":{
            //     background: "transparent",
            //     filter: "none"
            // }
            filter:"none",
            transform: "scale(1.025)",

            "&:before":{

            }
        },

        "&:before":{
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            inset: '0px',
            zIndex: '3',
            transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
            backgroundColor: colors.darkBlue,
            borderRadius: "3px",
            mixBlendMode: 'screen',
        },

    },


    featuredImage:{
        width: '100%',
        maxWidth: '100%',
        verticalAlign: 'middle',
        borderRadius: '3px',
        position: 'relative',
        transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
    },

    "@media only screen and (max-width: 768px)":{
        content:{
            gridColumn: "1/-1",
            padding: "40px 40px 40px",
            zIndex: "5",

        },

        projectName: {
            color: "white",
            fontSize: "24px",
            "& a":{
                display:"block"
            }
        },

        description: {
            backgroundColor:"transparent",
            padding:"20px 0",
            boxShadow:"none",
            "&:hover":{
                boxShadow:"none",
            }
        },

        techList: {
            "& li":{
                marginRight: "10px"
            }
        },

        imgContainer: {
            gridColumn:"1/-1",
        },

        featuredImage: {
            objectFit: "cover",
            width:"auto",
            height:"500px",
            filter: "grayscale(25%) brightness(25%) contrast(120%)"
        },

        project:{
            marginBottom:"70px",

            "&:nth-of-type(odd)":{
                "& $content":{
                    gridColumn:"1/-1",
                    padding: "40px 40px 30px",
                },

                "& $imgContainer":{
                    gridColumn:"1/-1",
                }
            }
        },


    },

}

export default style;