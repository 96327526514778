import * as colors from "assets/jss/material-kit-react/colors";

const projectTableStyle = {
    tableContainer: {
        marginTop: "50px",
        "& table": {
            width: "100%",
            borderCollapse: "collapse",
            "& th, & td": {
                textAlign: "left",
                padding: "10px",
                ":first-child": {
                    // paddingLeft: "20px"
                },
            },
            "& tr:hover": {
                backgroundColor: colors.lightBlue
            }
        }

    },
    date: {
        paddingRight: "20px",
        fontWeight: "600",
        color: colors.lightPink
    },
    title: {
        paddingTop: "15px",
        paddingRight: "20px",
        color: colors.metalGray,
        fontWeight: "600",
        display: "inline-block"
    },
    description: {},
    tech: {
        lineHeight: "1.5",
        fontSize: "0.8rem",
        "& span": {
            display: "inline-block",
        }
    },
    separator: {
        margin: "0 5px",
    },
    links: {
        minWidth: "100px",
        "& a": {
            margin: "0 5px",
            "& svg": {
                transition: "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s",
            },
            "&:hover svg": {
                color: colors.darkPink,
                transform: "scale(1.4)",
            }
        }

    },

}

export default projectTableStyle;