import React from "react";
import Carousel from "react-slick";
import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/homePageSections/carouselStyle.js";
import logoData from "./logoData";

const useStyles = makeStyles(styles);

export default function LogoCarousel() {
    const classes = useStyles();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
        ]
    };
    const logos = logoData;
    return (
        <div>
            <Carousel {...settings} className = {classes.carousel}>
                {logos.map((logo) => {
                    return(
                        <div className={classes.element}>
                            <img src = {logo} className={"slick-image"} alt={"A logo"}/>
                        </div>
                    )}
                )}
            </Carousel>
        </div>
    );
}
