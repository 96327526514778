import {lightPink} from "assets/jss/material-kit-react/colors";
import image from "assets/img/work.jpg";
import {blockTitle, container, title} from "assets/jss/material-kit-react";
import * as colors from "assets/jss/material-kit-react/colors";

const style = {
    container:{
        ...container,
        marginTop: "20px"
    },

    title: {
        ...title,
        // fontSize: "24px",
        fontSize: "32px",
        "&:before":{
            counterIncrement: 'section 2',
            content: '"0" counter(section) "."',
            marginRight: '10px',
            fontFamily: 'SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, monospace',
            fontWeight: 'normal',
            color: '#ff7eee',
            fontSize: '20px',
            position: 'relative',
            bottom: '4px',
        },
    },

    description: {
        // margin: "1.071rem auto 0",
        // fontSize: "20px",
        // maxWidth: "600px",
        // "& btn": {
        //     textAlign: "center"
        // }
        color: colors.textWhite
    },

    divider: {
        display: "block",
        margin: "auto auto 15px auto",
        width: "100px",
        height: "2px",
        // backgroundColor: digitalCyan,
        backgroundColor: lightPink,
    },

    backgroundOverlay: {
        backgroundAttachment: "fixed",
        backgroundPosition: "top center",
        backgroundSize: "cover",
        backgroundImage: "url(" + image + ")",
    },


    section: {
        padding: "70px 0",
        textAlign: "center",

        minHeight: "100vh",
        // maxHeight: "1600px",

        overflow: "hidden",
        border: "0",
        // display: "flex",
        // flexWrap:"wrap",

        marginTop: "25px",
        marginBottom: "50px",


        alignItems: "center",

        background: "linear-gradient(to bottom,rgba(0,0,0,.85),rgba(0,0,0,.85),rgba(0,0,0,.85),rgba(0,0,0,.85),rgba(0,0,0,.85))"
    },

    "@media only screen and (max-width: 768px)":{

    },

    "@media only screen and (min-width: 768px)":{
        borderBottom:{
            borderBottom: "1px solid rgba(242,242,242,.3)",
        },

        borderRight:{
            borderRight: "1px solid rgba(242,242,242,.3)"
        }
    },
}


export default style;