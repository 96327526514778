import React, {useContext} from "react";
import styles from "../../assets/jss/material-kit-react/views/journey";
import {LanguageContext} from "../../languages/Language";
import {makeStyles} from "@material-ui/core/styles";
import HeaderLinks from "../../components/Header/HeaderLinks";
import Header from "../../components/Header/Header";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import aboutContent from "../../languages/content/aboutContent";
import {ParallaxBanner, ParallaxProvider} from "react-scroll-parallax";
import classNames from "classnames";
import DotNav from "../../components/DotNav/DotNav";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/CustomButtons/Button";
import MailIcon from "@material-ui/icons/Mail";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";

const useStyles = makeStyles(styles);

export default function About() {
    const classes = useStyles();
    const localizedContent = useContext(LanguageContext).dictionary.aboutContent;
    const commonContent = aboutContent.common;
    const navLinksData = [];
    let i = 1;
    navLinksData.push({
        id: i,
        label: localizedContent.intro.title
    })
    Object.keys(localizedContent.main).map((id) =>
        {
            i = i+1;
            navLinksData.push({
                id: i,
                label: localizedContent.main[id].title})
        }
    )
    return(
        <div>
            <DotNav navLinks={navLinksData}/>
                <Header
                    color="transparent"
                    rightLinks={<HeaderLinks/>}
                    fixed = {false}
                    brand = {"Tuan-anh Bach"}
                    changeColorOnScroll={{
                        height: 700,
                        color: "dark"
                    }}
                />

            <ParallaxProvider>
                <div id={1}>
                    <ParallaxBanner
                        layers={[{
                            image: commonContent.intro.img,
                            amount: 0.2
                        }]}
                        style={{minHeight: "1080px"}}
                        className={classNames(classes.overlay, classes.parallax)}
                    >
                        <div className={classes.container} style={{margin: "auto", zIndex: "1", textAlign: "center"}}>
                            <GridContainer justify="center" alignItems={"center"}>
                                <GridItem xs={12} sm={12} md={12} className={classes.intro}>
                                    <h1 className={classes.title}
                                        style={{
                                            color: "rgb(240,240,240)",
                                            textTransform: "uppercase",
                                            fontSize: "4rem"
                                        }}>
                                        {localizedContent.intro.title}
                                    </h1>
                                    <p className={classes.description}
                                       style={{
                                           color: "rgb(240,240,240)",
                                           fontSize: "1.5rem",
                                           fontWeight: "300"
                                       }}>
                                        {localizedContent.intro.subtitle}
                                    </p>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </ParallaxBanner>
                </div>

                <div className={classes.alternating}>
                    {Object.keys(commonContent.main).map((mainHeroId, j) => {
                        j=j+2;
                        return(
                            <div id={j}>
                                <ParallaxBanner
                                    layers={[{
                                        image: commonContent.main[mainHeroId].img,
                                        amount: 0.2
                                    }]}
                                    // style={{minHeight: "1080px"}}
                                    className={classNames(classes.parallax)}
                                >
                                    <div className={classNames(classes.container)}>
                                        <GridContainer alignItems={"flex-start"} className={classes.gridContainer}>
                                            <GridItem xs={12} sm={12} md={8} className={classes.boxed}>
                                                <h1 className={classes.title}>
                                                    {localizedContent.main[mainHeroId].title}
                                                </h1>
                                                <p className={classes.description}>
                                                    {localizedContent.main[mainHeroId].desc}
                                                </p>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </ParallaxBanner>
                            </div>

                        )
                    })}
                </div>

                <ParallaxBanner
                    layers={[{image: commonContent.out.img, amount: 0.5}]}
                    className={classNames(classes.overlay, classes.parallax)}
                >
                    <div className={classes.container} style={{margin: "auto", zIndex: "1", textAlign: "center"}}>
                        <GridContainer justify="center" alignItems={"center"}>
                            <GridItem xs={12} sm={12} md={12} className={classes.intro}>
                                <h1 className={classes.title}
                                    style={{
                                        textTransform: "uppercase",
                                        fontSize: "2rem",
                                        color: "rgb(240,240,240)"
                                    }}>
                                    {localizedContent.out.title}
                                </h1>
                                <p className={classes.description}
                                   style={{
                                       fontSize: "1.5rem",
                                       fontWeight: "300",
                                       color: "rgb(240,240,240)"
                                   }}>
                                    {localizedContent.out.subtitle}
                                </p>
                                <div className={classes.endLink}>
                                    <ul>
                                        <li>
                                            <Button
                                                color="primary"
                                                size="lg"
                                                href="mailto:tuananh.bach.pro@gmail.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <MailIcon/>
                                                Email
                                            </Button>

                                        </li>

                                        <li>
                                            <Button
                                                color="primary"
                                                size="lg"
                                                href="https://www.linkedin.com/in/tuananh-bach/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <LinkedInIcon/>
                                                LinkedIn
                                            </Button>

                                        </li>

                                        <li>
                                            <Button
                                                color="primary"
                                                size="lg"
                                                href="https://github.com/Trukibouge"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <GitHubIcon/>
                                                GitHub
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </ParallaxBanner>

            </ParallaxProvider>
            <Footer/>
        </div>

    )
}