import {blockTitle, container, title} from "../../../material-kit-react";

import imagesStyle from "../../../material-kit-react/imagesStyles.js";

const featuredProjects = {
    container,
    profile: {
        textAlign: "center",
        "& img": {
            maxWidth: "160px",
            width: "100%",
            margin: "0 auto",
            transform: "translate3d(0, -50%, 0)"
        }
    },

    description: {
        margin: "1.071rem auto 0",
        maxWidth: "600px",
        textAlign: "center !important"
    },

    name: {
        marginTop: "-80px"
    },
    ...imagesStyle,

    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    title: {
        ...title,
    },

    blockTitle: {
        ...blockTitle,
        counterIncrement: 'section 4',
    },

    socials: {
        marginTop: "0",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
    },

    navWrapper: {
        margin: "20px auto 50px auto",
        textAlign: "center"
    },
}

export default featuredProjects;