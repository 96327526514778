import {blockTitle, container} from "../../../material-kit-react.js";

const timelineStyle = {
  container: {
    ...container,
    padding: "50px",
    // backgroundColor: "#999"
  },

  blockTitle: {
    ...blockTitle,
    counterIncrement: 'section 3',
  },

  title:{
    fontWeight:"500",
    margin:"0",
    fontSize: "1.5625rem",
  },

  subtitle:{
    margin:"0",
  },

  verticalTimelineElement:{
    '& .vertical-timeline-element-date':{
      color: "#fff",
    },
    "& .vertical-timeline-element-content":{
      backgroundColor: "rgb(240, 240, 240)",
      color: "#3c4858"
    },
    "& ul":{
      paddingLeft:"0"
    },
    "& li":{
      listStyleType: "none",
      marginTop:"0.6rem",
      "&:before":{
        content:"'- '"
      }
    },
  },

  major:{
    "& .vertical-timeline-element-content":{
      backgroundColor: "#25274D",
      color: 'rgb(168, 178, 209)'
    },

    "& $bottomRight":{
      position:"absolute",
      bottom: "0px",
      right:"16px",
      fontSize:"40px",
      fontWeight:"1000",
      opacity:"0.10"
    },
  },

  bottomRight:{},

  work:{
    '& .vertical-timeline-element-content':{
      borderTop: '3px solid #2E9CCA ',
      boxShadow: '0 3px 0 0px rgba(0, 0, 0, 0.05)',
    },
  },

  education:{
    '& .vertical-timeline-element-content':{
      borderTop: '3px solid #e91e63',
      boxShadow: '0 3px 0 0px rgba(0, 0, 0, 0.05)',
    }
  },

  school:{
    '& .vertical-timeline-element-content':{
      borderTop: '3px solid #45a508',
      boxShadow: '0 3px 0 0px rgba(0, 0, 0, 0.05)',
    }
  },

  details:{
    fontWeight: "200!important"
  },

  "@media only screen and (max-width: 768px)": {
    verticalTimelineElement: {
      "& .vertical-timeline-element-date ": {
        color: "#3c4858"
      }
    },

    major: {
      "& .vertical-timeline-element-date ": {
        color: 'rgb(168, 178, 209)'
      },
    },

  }
}

export default timelineStyle;
