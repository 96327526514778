import React from "react";
import {Route, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

import HomePage from "views/HomePage/Homepage";
import About from "views/About/About.js";
import Projects from "views/Projects/Projects.js";
import Resume from "views/Resume/Resume.js";
import {LanguageProvider} from "./languages/Language";

function App() {
    return (
        <LanguageProvider>
            <Switch>
                <Route path="/journey" component={About}/>
                <Route path="/projects" component={Projects}/>
                <Route path="/resume" component={Resume}/>
                <Route path="/" component={HomePage}/>
            </Switch>
        </LanguageProvider>
    );
  }
  
export default App;
