const projectTableContent = {
    en: {
        date: "Date",
        title: "Title",
        desc: "Short Description",
        tech: "Technology",
        link: "Link(s)"
    },
    jp: {
        date: "日付",
        title: "項目名",
        desc: "抄録",
        tech: "技術",
        link: "リンク先"
    }
}

export default projectTableContent;