import { container, title } from "../../material-kit-react";

const homePageStyle = {
  container: {
    zIndex: 12,
    ...container,
    "& h3":{
      // fontSize:"20px"
    }
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "0px",
    minHeight: "32px",
    // color: "#2E9CCA",
    textDecoration: "none",
    fontSize: "50px"
  },

  subtitle: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "0px",
    minHeight: "32px",
    color: "#29648A",
    textDecoration: "none",
  },

  // subtitle: {
  //   fontSize: "1.5rem",
  //   maxWidth: "500px",
  //   margin: "10px auto 0",
  // },
  
  main: {
    background: "#464866",
    position: "relative",
    zIndex: 3
  },

  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },

  overlay: {
    "&:before": {
      background: "rgba(0,0,0,.75)"
    },

    minHeight: "800px"
  },

  "@media only screen and (min-width: 768px)":{
    mainRaised:{
      margin: "-60px 2rem 0px 7rem",
    }
  }
};

export default  homePageStyle;
