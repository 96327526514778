import React, {useContext} from "react";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import {makeStyles} from "@material-ui/core/styles";

import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import {LanguageContext} from "../../languages/Language";
import {projectData} from "../../languages/content/projectContent";
import styles from "../../assets/jss/material-kit-react/views/projectArchiveStyle";
import ProjectTable from "../../components/ProjectTable/ProjectTable";

import * as colors from "assets/jss/material-kit-react/colors"
import Footer from "../../components/Footer/Footer";

const useStyles = makeStyles(styles);
export default function Projects() {
    const classes = useStyles();
    const projectLanguageDependantData = useContext(LanguageContext).dictionary.projectDetails;
    const commonProjectData = projectData.projectsCommonData;
    commonProjectData.map((project) => {
        Object.assign(project, projectLanguageDependantData[project.id])
    })
    const pageContent = useContext(LanguageContext).dictionary.archiveContent;

    return (
        <div>
            <Header
                color="transparent"
                rightLinks={<HeaderLinks/>}
                fixed={false}
                brand={"Tuan-anh Bach"}
                changeColorOnScroll={{
                    height: 700,
                    color: "dark"
                }}
            />
            {/*<div className={classNames(classes.main, classes.mainRaised)}>*/}
            <div className={classes.container}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                        <h1 className={classes.title}>
                            {pageContent.title}
                        </h1>
                        <p style={{color: colors.lightPink}}>{pageContent.desc}</p>
                    </GridItem>
                </GridContainer>
                <ProjectTable data={commonProjectData}/>
            </div>
            {/*</div>*/}
            <Footer/>

        </div>
    )
}