import {container} from "assets/jss/material-kit-react";

const carouselStyle = {
    section: {
        padding: "70px 0"
    },
    container,
    marginAuto: {
        marginLeft: "auto !important",
        marginRight: "auto !important"
    },

    element: {
        "& .slick-image": {
            height: "150px"
        },
    },

    carousel: {
        "& .slick-slide": {
            paddingRight: "50px"
        },
    },

    "@media only screen and (max-width: 768px)": {
        carousel: {
            "& .slick-slide": {
                paddingRight: "0px"
            },
        },
    }
};

export default carouselStyle;
