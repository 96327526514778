import {blockTitle, container, title} from "../../../material-kit-react.js";

import imagesStyle from "../../../material-kit-react/imagesStyles.js";
import {metalGray} from "../../colors";

const introductionStyle = {
  container: {
    ...container,
    padding: "20px",
    marginTop: "100px",
    marginBottom: "100px"
  },

  description: {
    margin: "1.071rem auto 0",
    fontSize: "20px",
    maxWidth: "600px",
    "& btn": {
      textAlign: "center"
    }
  },

  name: {
    // marginTop: "-80px"
  },

  ...imagesStyle,

  main: {
    background: "#464866",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  title: {
    ...title,
  },
  
  blockTitle: {
    ...blockTitle,
    counterIncrement: 'section 0',
  },

  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },

  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center"
  },


  imageWrap: {
    position: "relative",
    width: "60%",
    maxWidth: "1024px",
    margin: "auto",
    transition: "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s",

    "a":{
      "&:focus":{
        outline: "0"
      }
    },

    "&:hover $imageDescriptionLayer":{
      visibility: "visible",
      opacity: "1"
    },

    "&:hover $imageDescription":{
      transform: "translateY(0)",
    },

    "&:after":{
      ...imagesStyle.imgRounded,
      // backgroundColor: "rgb(37,39,77, 0.5)",
      content: "''",
      display: "block",
      position: "absolute",
      width:"100%",
      height:"100%",
      top: "20px",
      left: "20px",
      border: `2px solid ${metalGray}`,
      zIndex: "-1",
      transition: "200ms",
    },

    "&:hover:after":{
      top: "10px",
      left: "10px",
    }
  },

  imageDescriptionLayer:{
    ...imagesStyle.imgRounded,
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    background: 'rgba(0,0,0,0.6)',
    color: '#fff',
    fontSize: "24px",
    visibility: 'hidden',
    opacity: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: "600ms",
    padding: "50px",
    textAlign: "center"
  },

  imageDescription: {
    transition: "600ms",
    transform: "translateY(3em)",
  },

  "@media only screen and (min-width: 768px)":{

  },

  "@media only screen and (max-width: 768px)":{
    imageWrap: {
      margin: "60px auto 0",
      width: "70%",
    }
  }
};

export default introductionStyle;
