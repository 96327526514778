import React, {useContext} from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import classNames from "classnames";
import InfoArea from "../../../components/InfoArea/InfoArea";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CodeIcon from "@material-ui/icons/Code";
import WebIcon from "@material-ui/icons/Web";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/homePageSections/whatIDoStyle";

import {LanguageContext} from "languages/Language";

const useStyles = makeStyles(styles);

export default function WhatIDo({id}){
    const classes = useStyles();
    const content = useContext(LanguageContext).dictionary.whatIDoContent;
    return(
        <div className = {classes.backgroundOverlay} id = {id}>
            <div className={classes.section}>

                <GridContainer justify="center" className={classes.container}>
                    <GridItem xs={12} sm={12} md={12}>
                        <h1 className={classes.title}>{content.title}</h1>
                        <span className={classes.divider}/>
                        <h5 className={classNames(classes.description)}>
                            {content.subtitle}
                        </h5>
                    </GridItem>
                </GridContainer>

                <div className = {classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6} className = {classNames(classes.borderBottom, classes.borderRight)}>
                            <InfoArea
                                title={content.software.title}
                                description={content.software.description}
                                icon={AccountTreeIcon}
                                iconColor="danger"
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6} className = {classes.borderBottom}>
                            <InfoArea
                                title={content.fullstack.title}
                                description={content.fullstack.description}
                                icon={CodeIcon}
                                iconColor="info"
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} className={classes.borderRight}>
                            <InfoArea
                                title={content.experience.title}
                                description={content.experience.description}
                                icon={WebIcon}
                                iconColor="success"
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <InfoArea
                                title={content.process.title}
                                description={content.process.description}
                                icon={AutorenewIcon}
                                iconColor="warning"
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    )
}