import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/components/projectTableStyle";
import PropTypes from "prop-types";
import {LanguageContext} from "../../languages/Language";
import GitHubIcon from "@material-ui/icons/GitHub";
import * as colors from "../../assets/jss/material-kit-react/colors";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import LinkIcon from "@material-ui/icons/Link";

const useStyles = makeStyles(styles);

export default function ProjectTable({data}) {
    const classes = useStyles();
    const sortedData = data.sort((a, b) => parseInt(a.date) > parseInt(b.date) ? -1 : 1)
    const tableLanguageData = useContext(LanguageContext).dictionary.projectTableContent;
    return (
        <div className={classes.tableContainer}>
            <table>
                <thead>
                <th>{tableLanguageData.date}</th>
                <th>{tableLanguageData.title}</th>
                {/*<th>{tableLanguageData.desc}</th>*/}
                <th>{tableLanguageData.tech}</th>
                <th>{tableLanguageData.link}</th>
                </thead>
                <tbody>
                {sortedData.map((project) => {
                    return (
                        <tr>
                            <td className={classes.date}>{project.date}</td>
                            <td className={classes.title}>{project.projectName}</td>
                            {/*<td className={classes.description}>{project.shortDescription}</td>*/}
                            <td className={classes.tech}>{project.techList.map((tech, i) => {
                                return (
                                    <span>
                                        {tech}
                                        {project.techList.length !== i + 1 &&
                                        <span className={classes.separator}>·</span>}
                                    </span>)
                            })}</td>
                            <td className={classes.links}>
                                {
                                    project.github &&
                                    <a
                                        href={project.github}
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        aria-label="GitHub Link">
                                        <GitHubIcon htmlColor={colors.metalGray}/>
                                    </a>
                                }
                                {
                                    project.report &&
                                    <a
                                        href={project.report}
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        aria-label="Report Sample"
                                    >
                                        <PictureAsPdfIcon htmlColor={colors.metalGray}/>
                                    </a>
                                }
                                {
                                    project.link &&
                                    <a
                                        href={project.link}
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        aria-label="GitHub Link">
                                        <LinkIcon htmlColor={colors.metalGray}/>
                                    </a>}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

ProjectTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
}