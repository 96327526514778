import {projectData} from "languages/content/projectContent"
import {skillContent} from "./content/skillContent";
import {homepageContent} from "./content/homepageContent"
import {introductionContent} from "./content/introductionContent";
import {whatIDoContent} from "./content/whatIDoContent";
import timelineContent from "./content/timelineContent";
import lateralBarContent from "./content/lateralBarContent";
import headerContent from "./content/headerContent";
import aboutContent from "./content/aboutContent";
import projectTableContent from "./content/projectTableContent";
import archiveContent from "./content/archiveContent";
import contactContent from "./content/contactContent";

const languageOptions = {
    en: "English",
    fr: "Français",
    jp: "日本語"
}

const dictionaryList = {
    en: {
        homepageContent: homepageContent["en"],
        introductionContent: introductionContent["en"],
        whatIDoContent: whatIDoContent["en"],
        skills: skillContent["en"],
        timeline: timelineContent["en"],
        projectDetails: projectData.en,
        lateralBarContent: lateralBarContent.en,
        headerContent: headerContent.en,
        aboutContent: aboutContent.en,
        projectTableContent: projectTableContent.en,
        archiveContent: archiveContent.en,
        contactContent: contactContent.en
    },
    jp: {
        aboutContent: aboutContent.jp,
        headerContent: headerContent.jp,
        homepageContent: homepageContent.jp,
        introductionContent: introductionContent.jp,
        whatIDoContent: whatIDoContent.jp,
        skills: skillContent.jp,
        timeline: timelineContent.jp,
        projectDetails: projectData.jp,
        contactContent: contactContent.jp,
        lateralBarContent: lateralBarContent.jp,
        archiveContent: archiveContent.jp,
        projectTableContent: projectTableContent.jp,
    }
}

export{languageOptions, dictionaryList}