import {blockTitle, container, title} from "../../../material-kit-react";
import image from '../../../../img/work.jpg';
import * as colors from "../../colors";

const skillsStyle = {
    container,

    title,

    blockTitle: {
        ...blockTitle,
        counterIncrement: 'section 2',
    },

    subtitle:{
        ...title,
        fontSize: "24px"
    },


    section: {
        padding: "70px 0",
        textAlign: "center",
        overflow: "hidden",
        border: "0",
        marginTop: "25px",
        marginBottom: "50px",
        alignItems: "center",
        background: "linear-gradient(to bottom,rgba(0,0,0,.85),rgba(0,0,0,.85),rgba(0,0,0,.85),rgba(0,0,0,.85),rgba(0,0,0,.85))"
    },

    divider:{
        display: "block",
        margin: "auto auto 15px auto",
        width: "100px",
        height: "2px",
        backgroundColor: colors.digitalCyan,
    },

    backgroundOverlay:{
        backgroundAttachment: "fixed",
        backgroundPosition: "top center",
        backgroundSize: "cover",
        backgroundImage: "url(" + image + ")",
    },


    icon:{
        width: '50px',
        height: '50px',
        marginRight: '20px',
        position: 'relative',
        top: '7px',
        display: 'inline-block',
    },

    iconBackground:{
        backgroundColor: "#1976d2",
        boxSizing: "content-box",
        borderRadius: "4px",
    },

    skillLists:{
        "& li":{
            listStyle: "none",
            fontSize: ".8rem",
            lineHeight: '1',
            display: 'inline-block',
            marginRight: '5px',
            marginBottom: '5px',
            padding: '10px 15px',
            borderRadius: '5px',
            border: "1px solid transparent",
            transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
            backgroundColor: "rgb(37,39,77, 0.5)",
            fontWeight:"500",
            cursor: "default",
            "&:hover":{
                backgroundColor: "rgb(37,39,77)",
                border: "1px solid rgb(168, 178, 209)",
                "& $skillLevel":{
                    fontSize: "16px"
                },
                "& $flagIcon":{
                    maxWidth: "32px"
                }
            },
        },
        "& strong":{
            color: colors.metalGray
        }
    },

    languages:{
        "& li":{
            display: "block",
            "& p":{
                fontSize:"0.8rem",
                paddingLeft: "5px",
                marginBottom:"0",
                marginTop:"5px"
            }
        },


    },

    flagIcon:{
        maxWidth: "25px",
        marginRight:"5px",
        marginLeft:"5px",
        marginBottom:"5px",
        transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
    },

    skillCategory:{
        color: colors.metalGray,
        textTransform: "uppercase",
        fontWeight:"700"
    },

    faPrimary:{
        color: colors.metalGray,
    },

    faSecondary:{
        color: "rgb(179,187,214)",
    },

    skillLevel:{
        float: "right",
        fontSize: "14px",
        transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
        // color: colors.softGray

    },

    progLanguages:{
        width:"49%"
    },

    "@media only screen and (max-width: 768px)":{
        progLanguages:{
            width:"100%"
        }
    }

}



export default skillsStyle