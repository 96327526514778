import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router} from "react-router-dom";

// import "assets/scss/material-kit-react.scss?v=1.9.0";
import App from './App';
import RestoreScrollTop from "./components/RestoreScrollTop/RestoreScrollTop";


let hist = createBrowserHistory();
ReactDOM.render(
    <React.StrictMode>
        <Router history={hist}>
            <RestoreScrollTop/>
            <App/>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);