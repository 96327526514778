import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/components/featuredProjectStyle";
import PropTypes from "prop-types";

import GitHubIcon from '@material-ui/icons/GitHub';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import LinkIcon from "@material-ui/icons/Link";
import * as colors from "assets/jss/material-kit-react/colors";

const useStyles = makeStyles(styles);
export default function FeaturedProject({data}){
    const classes = useStyles();
    const featuredProjects = data.filter((project) => project.featured);
    return(
        <div className={classes.container}>
            {featuredProjects.map((project, i) => {
                let mainLink = "";
                if (project.link) mainLink = project.link;
                else if (project.report) mainLink = project.report;
                else if (project.github) mainLink = project.github;

                return (
                    <div className={classes.project} key={i} id={project.id}>
                        <div className={classes.content}>
                            <div className={classes.label}>
                                {project.date}
                            </div>
                            <div className={classes.projectName}>
                                {project.projectName}
                            </div>
                            <div className={classes.description}>
                                <p>{project.description}</p>

                            </div>
                            <ul className={classes.techList}>
                                {project.techList.map((tech) =>
                                    <li>{tech}</li>
                                )}
                            </ul>
                            <div className={classes.linkWrapper}>
                                {
                                    project.github &&
                                    <a
                                        href={project.github}
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        aria-label="GitHub Link">
                                        <GitHubIcon htmlColor={colors.metalGray}/>
                                    </a>
                                }
                                {
                                    project.report &&
                                    <a
                                        href={project.report}
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        aria-label="Report Sample"
                                    >
                                        <PictureAsPdfIcon htmlColor={colors.metalGray}/>
                                    </a>
                                }
                                {
                                    project.link &&
                                    <a
                                        href={project.link}
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        aria-label="GitHub Link">
                                        <LinkIcon htmlColor={colors.metalGray}/>
                                    </a>
                                }
                            </div>
                        </div>

                        {mainLink !== "" && <a href={mainLink} target="_blank" rel="nofollow noopener noreferrer"
                                               className={classes.imgContainer}>
                            <img src={project.imgSrc} alt="My Picture" className={classes.featuredImage}/>
                        </a>}

                        {mainLink === "" && <div className={classes.imgContainer}>
                            <a href={mainLink} target="_blank" rel="nofollow noopener noreferrer"
                               aria-label="Main Link">
                                <img src={project.imgSrc} alt="My Picture" className={classes.featuredImage}/>
                            </a>
                        </div>}
                    </div>
                )
            })}
        </div>

    )
}

FeaturedProject.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
}