import {container, title} from "assets/jss/material-kit-react";

const projectArchiveStyle = {
    container: {
        ...container
    },
    title: {
        ...title
    },
    main: {
        background: "#464866",
        position: "relative",
        zIndex: 3
    },

    mainRaised: {
        margin: "0px",
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
}

export default projectArchiveStyle;