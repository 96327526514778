import React, {useContext} from "react";

import styles from "../../../assets/jss/material-kit-react/views/homePageSections/featuredProjects";

import makeStyles from "@material-ui/core/styles/makeStyles";
import FeaturedProject from "../../../components/FeaturedProject/FeaturedProject";

import {LanguageContext} from "languages/Language";
import {projectData} from "languages/content/projectContent";

const useStyles = makeStyles(styles);

export default function FeaturedProjects({id}){
    const classes = useStyles();
    const projectLanguageDependantData = useContext(LanguageContext).dictionary.projectDetails;
    const commonProjectData = projectData.projectsCommonData;

    commonProjectData.map((project) => {
            Object.assign(project, projectLanguageDependantData[project.id])
    })


    return(
        <div className={classes.container} id={id}>
            <div className={classes.blockTitle}>
                {projectLanguageDependantData.categoryName}
            </div>

            <div className={classes.navWrapper}>
                {
                    <FeaturedProject data = {commonProjectData}/>
                }
            </div>
        </div>
    )
}