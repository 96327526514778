const lateralBarContent ={
    en: {
        introduction: "Introduction",
        whatIDo: "What I Do",
        skills: "Skills",
        education: "Education & Experience",
        featuredProjects: "Featured Projects",
        contact: "Contact"
    },
    jp: {
        introduction: "自分について",
        whatIDo: "私の主な分野",
        skills: "スキル一覧",
        education: "履歴",
        featuredProjects: "注目のプロジェクト紹介",
        contact: "お問い合わせ"
    }
}

export default lateralBarContent;