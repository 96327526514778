import first from "assets/img/about/IMG_4068.JPG"
import pabco from "assets/img/about/35BE5D38-9B73-4064-A6E2-F528D3A06900.jpeg"
import covid from "assets/img/about/P4210852.JPG"
import emse from "assets/img/about/emse.jpg"
import gaylu from "assets/img/about/gaylu.jpg"
import niig from "assets/img/about/P1060865.JPG"
import stjo from "assets/img/about/stjo.jpg"
import end from "assets/img/about/end.JPG"
import child from "assets/img/about/child.jpg"
import pcb from "assets/img/about/pcb.jpg"

const aboutContent = {
    common:{
        intro:{
            img: first
        },
        main:{
            covid:{
                img: covid
            },
            pabco:{
                img:pabco
            },
            mgate:{
                img: pcb
            },
            mines:{
                img:emse
            },
            cpge:{
                img:gaylu
            },
            niig:{
                img: niig
            },
            hs:{
                img: stjo
            },
            child:{
                img: child
            },
        },

        out:{
            img: end
        },
    },
    en:{
        intro:{
            title: "Everyone has a story beyond a resume.",
            subtitle: "My name is Tuan-Anh, and this is mine.",
        },
        main:{
            covid:{
                title:"Freelancing during the COVID-19 crisis",
                desc: "2020 is a difficult period to many. I'm no exception: the research project I had planned to carry out at Tohoku University, Japan, got postponed a few days before my departure, and the whole situation shortly left me in limbo. However, with hardships come opportunities.  I decided to focus the first half of 2020 at improving myself, both as a person and as a job prospect. Firstly, by finally giving myself time to enjoy my hobbies: playing jazz drums, working on personal software projects, drawing and painting, cycling. Secondly, by learning new skills and updating my toolkit to recent now-powerhouse technologies. In the meantime, various Japanese companies looking up for Software Engineering contractors contacted me, and I took this opportunity to hone my full-stack development skills as well as my Japanese."

            },
            pabco:{
                title: "Daimler Trucks Asia",
                desc: "I moved to Japan in February 2019 for the second time to work for Daimler Trucks Asia's Pabco. Thus, I finally fulfilled the goal I had of living independently in Japan -and Tokyo at that-! That is a goal I set after moving back to France following my year-long homestay in Japan. Despite being an intern, I had the chance to be responsible for two projects that both allowed me to collaborate as equals with engineers but also machine operators.",
                courtesy: "Photo courtesy of Pabco Co. Ltd."
            },
            mgate:{
                title:"M-GaTE",
                desc:"In 2018, I joined M-GaTE, the Junior-Enterprise of Ecole des Mines de Saint-Etienne. As a Project Manager for that consulting company, I accompanied customers having innovative project ideas and helped them push them through by formalizing requirements, architecting solutions and assembling teams to create PoCs."
            },
            mines:{
                title:"Ecole des Mines de Saint-Etienne",
                desc:"I joined EMSE in 2017 to study for my master's degree in computer science and microelectronics. The reason I chose this school among the arguably better choices I had was first because of this dual specialization, offering great versatility and the possibility to delve deeper in one or the other skillset if needs be. Secondly, the focus on international experience through a mandatory internship abroad and exchange semester is what enticed me to chose EMSE. In particular, Tohoku University, one of EMSE's partner, is an institution that allured me since I was in high school in Japan. As I joined EMSE, I set myself to later join Tohoku University by using the relationships between the two institutions."
            },
            cpge:{
                title:"Grandes Ecoles Preparatory Class",
                desc:"Soon after coming back from Japan, I had to sit for the science Baccalauréat and joined shortly after Gay-Lussac CPGE, a medium-size preparatory school in Limoges. The two years I spent there studying Maths, Physics, Chemistry and Mechanical Engineering proved the most challenging of my life, even as of now. I had to balance my life around 45 hours of classes, hour-long oral exams twice a week and 4-hour written exams every weekend. This way-of-life reached its peak when I had to finally sit for written and oral exams to the Grandes Ecoles, France's most prestigious schools. Nevertheless, I found joy working on my graduation research paper on Solid Propellant and combustion chambers, and I have to admit I let myself go a bit crazy for the sake of some explosive experimentations. "
            },
            niig:{
                title: "Niigata Commercial High School",
                desc: "In 2013, I decided to do something unusual, even exceptional, as I felt highly dissatisfied with my routine attending a French high school. I set my eyes on doing a homestay and studying abroad as far as possible through AFS Exchange Programs. Then, the next year, it became decided that I would be attending high school in Japan, and so I did. What a year it was! I found myself struggling to get through the cultural and language barriers at 17, attending class as an ordinary student without preferential treatment, to the point I had to take a week of exams alongside everyone at the beginning of the school year. Quite obviously, I averaged scores next to 10 (out of 100). Yet I did not give up, and I felt my Japanese improving as I made friends and lived my best life as a Japanese student, actively participating in classes and school life through clubs and associations.  Unshackled by biases I might have had at home, initial hardships transformed into opportunities to learn and discover much about others and myself. I would not trade anything for this experience, and I am confident that my returning to France following this exchange is the step that made me become the adult I am today.",
            },
            hs:{
                title:"Saint-Joseph High School",
                desc:"I am grateful for the time I spent at Saint-Joseph HS, in my small hometown of Périgueux. Thanks to the tremendous work of my now-defunct Maths teacher, my class was able to become aeronautics-oriented, which was a real boon as I was aiming to become a civilian pilot then. Alongside visits to the Kennedy Space Center, which was mindblowing for the aerospace nerd I was, I aimed at getting a private pilot license and participated in Aerospace-themed competitions, such as the Prix Aéronautique & Espace Aquitaine 2012. In the end, I chose to focus on becoming an engineer because of my poor eyesight, which eventually led me to become a Computer Science and Microelectronics graduate at EMSE.",
            },
            child: {
                title: "Planes, rockets, and computers",
                desc: "These were my childhoods love. It's hard to pinpoint when and how my obsession with planes and rockets started, as I was spending all my time playing with models before I realized. When I wasn't playing with my tiny planes, I would hop on a computer and tinker with it. I often remember stumbling into crashing software or system issues, and spending hours trying to solve them. Now, the solutions to these issues may seem trivial, such as editing and manipulating files in AppData. By then, it was real feats for me. Around 11, I started playing around kernels and low-level software to jailbreak my portable consoles. I wanted to use beautiful custom themes and play homebrews, without bricking anything, of course! More than for practical purpose, it was the sense of achievement that pushed me to make these attempts."
            },
        },
        out: {
            title: "Thank you for hearing me!",
            subtitle: "Feel free to contact me via the following channels:"
        }
    },


    jp: {
        intro: {
            title: "履歴書の向こう側にあるストーリー",
            subtitle: "",
        },
        main: {
            covid: {
                title: "新型コロナウイルス危機の中でのフリーランス活動",
                desc: "2020年は多くの人が困難に直面した年です。私も例外ではなく、東北大学で計画していた研究が出発の数日前に延期されてしまい、あっという間に宙ぶらりんの状態になってしまいました。しかし、苦難の時にはチャンスがあります。 2020年の前半は、自分を磨くことに集中し、趣味とスキル両方に時間とエネルギーをかけることができました。まず第一に、ジャズドラムを演奏したり、個人的なソフトウェアプロジェクトに取り組んだり、絵を描いたり、サイクリングをしたりと、自分の趣味を味わって楽しむ時間を作りました。第二に、新しい技術を学び、自分のツールキットに最新の高度な技術を取り入れました。そうこうしているうちに、ソフトウェアエンジニアリングの請負業者を探している日本の様々な企業から連絡があり、この機会にフルスタック開発のスキルと日本語を磨くことができました。"

            },
            pabco: {
                title: "ダイムラートラックアジア",
                desc: "2019年2月に、ダイムラートラックスアジアの系列企業である株式会社パブコで働くために、日本への二度目の引っ越しを果たしました。こうして、私はついに日本で自立して生活するという夢を達成しました。これは、高校時代の日本での10ヶ月のホームステイを終えフランスに戻った後に決めたものです。インターンシップであるにも関わらず、2つのプロジェクトを担当する機会があり、エンジニアだけでなく機械オペレーターとも対等に協力することができました。",
                courtesy: "Photo courtesy of Pabco Co. Ltd."
            },
            mgate: {
                title: "M-GaTE",
                desc: "2018年、Ecole des Mines de Saint-Etienne(サン＝テティエンヌ国立高等鉱業学校)のジュニアエンタープライズであるM-GaTEに入社しました。プロジェクトマネージャーとして、革新的なプロジェクトのアイデアを持っているお客様に同行し、要件の形式化、ソリューション設計を行い、PoCを作成するためのチームの組み立てなど、顧客への手助けをしました。"
            },
            mines: {
                title: "Ecole des Mines de Saint-Etienne",
                desc: "２０１７年にＥＭＳＥに入学し、計算機科学(CS)と電子工学(Microelectronics)の修士課程に入学しました。数ある選択肢の中からこの学校を選んだ理由は、第一に、二つの専門を学ぶことができ、汎用性が高く、必要に応じてどちらか一方のスキルセットを深く掘り下げられる可能性があったためです。第二に、海外でのインターンシップと交換留学を通じた国際的な経験に重点を置いているためです。特に、EMSEの協定校の一つである東北大学は、日本の高校に在学している時から憧れの教育機関でした。EMSEに入学した私は、その二つの機関の関係性を利用して、後に東北大学に入学することを目標にしました。"
            },
            cpge: {
                title: "グランゼコール準備級",
                desc: "日本から帰国してすぐに理系のバカロレアを受験し、リモージュの予備校Gay-Lussac CPGEに入学しました。そこでは2年間、数学、物理学、化学、機械工学を学んでいましたが、今までの人生の中で最も困難な時期でした。週に45時間の授業、それに週2回の1時間の口頭試問、毎週末の4時間の筆記試験を中心に生活のバランスを取らなければなりませんでした。その困難のピークは、フランスの名門校であるGrandes Ecolesの筆記試験と口述試験を受けなければならなくなった時でした。色々と大変なことがあった時期ではありましたが、とはいえ、卒業研究の固体推進剤と燃焼室に関する論文執筆と実験には喜びを見出して取り組むことができました。"
            },
            niig: {
                title: "新潟商業高校",
                desc: "2013年に、フランスの高校生活に不満を感じていた自分は、思い切ったことをしようと決心しました。それは、AFS協会のプログラムを利用し、日本にホームステイする形で留学することでした。そして翌年、日本の高校に通うことが決まりました。17歳の自分は、文化や言葉の壁を乗り越えることに努力を尽くしました。何もかもが分からなくとも諦めずに、友達を作り、部活に積極的に参加し、授業に集中して学校生活を送ることで、日本語が上達していくのを感じました。 フランスでの生活から完全に解放された後の最初の苦難は、他の人や自分自身について多くのことを学び、発見する機会に変わりました。この時の留学経験は、今の私を大人にしてくれた一歩であったと確信しています。",
            },
            hs: {
                title: "Saint-Joseph高校",
                desc: "今は亡き数学の先生の多大な努力のおかげで、自分のクラスの授業は航空学を中心としたものになり、私はパイロットになることを目指していました。プライベートパイロットのライセンス取得を目指しながら、友達とよくグライダーを乗り、航空宇宙をテーマにしたコンテストに参加しました。最終的には、視力の悪さからエンジニアになることを選択し、EMSEでcsと電子工学を専攻することになりました。",
            },
            child: {
                title: "飛行機、ロケット、コンピュータ",
                desc: "子供の頃から大好きだったものです。飛行機やロケットに夢中になったのがいつ、どのようにして始まったのか、はっきりと説明することは難しいのですが、いつの間にかずっと模型で遊んでいました。小さな飛行機の模型で遊んでいない時は、パソコンをいじって遊んでいました。思い出深いのは、ソフトのクラッシュに遭遇して、何時間もかけて問題を解決しようとしたことです。.iniファイルを編集したり、AppDataでファイルを操作したりするなどそれらの問題の解決策は、今の私にとっては些細なことのように思えます。しかし、あの頃の自分にとっては本当の偉業でした。"
            },
        },
        out: {
            title: "Thank you for hearing me!",
            subtitle: "以下の方法でお気軽にご連絡ください。"
        }
    }
}

export default aboutContent;