import React, {useContext} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {animateScroll, Link} from "react-scroll";

import styles from "../../assets/jss/material-kit-react/components/lateralBarStyle";
import {LanguageContext} from "../../languages/Language";

const useStyles = makeStyles(styles);
export default function LateralBar(){
    const content = useContext(LanguageContext).dictionary.lateralBarContent;
    const classes = useStyles();
    return(
        <div>
            <nav className={classes.navBar}>
                <ul className={classes.navBarNav}>
                    <li className={classes.logo}>
                        <Link to="Top"
                              className={classes.navLink}
                              onClick={() => {animateScroll.scrollToTop()}}
                        >
                            <span className={classes.logoText}>T. Bach</span>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="angle-double-right"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                className="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                            >
                                <g className="fa-group">
                                    <path
                                        fill="currentColor"
                                        d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                        className={classes.faSecondary}
                                        />
                                    <path
                                        fill="currentColor"
                                        d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                        className={classes.faPrimary}
                                        />
                                </g>
                            </svg>
                        </Link>
                    </li>

                    <li className={classes.navItem}>
                        <Link to="intro"
                              activeClass={classes.active}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className={classes.navLink}>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="cat"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                className="svg-inline--fa fa-cat fa-w-16 fa-9x"
                            >
                                <g className={classes.faPrimary} fill={"currentColor"}>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m263.839 102.321c-112.948 0-204.839 91.891-204.839 204.84s91.891 204.839 204.839 204.839 204.839-91.891 204.839-204.839-91.89-204.84-204.839-204.84zm-59.551 127.526c21.351 0 38.722 17.371 38.722 38.722 0 8.284-6.716 15-15 15s-15-6.716-15-15c0-4.809-3.913-8.722-8.722-8.722s-8.722 3.913-8.722 8.722c0 8.284-6.716 15-15 15s-15-6.716-15-15c0-21.352 17.371-38.722 38.722-38.722zm150.627 136.558c-22.834 26.622-56.03 41.89-91.075 41.89-35.044 0-68.239-15.268-91.074-41.888-5.394-6.288-4.669-15.758 1.619-21.151s15.758-4.668 21.151 1.619c17.128 19.968 42.024 31.42 68.303 31.42 26.28 0 51.176-11.453 68.305-31.421 5.394-6.288 14.864-7.012 21.151-1.62 6.288 5.394 7.013 14.864 1.62 21.151zm-7.802-82.837c-8.284 0-15-6.716-15-15 0-4.809-3.913-8.722-8.722-8.722s-8.722 3.913-8.722 8.722c0 8.284-6.716 15-15 15s-15-6.716-15-15c0-21.351 17.371-38.722 38.722-38.722s38.722 17.371 38.722 38.722c0 8.285-6.716 15-15 15z"/>

                                </g>
                                <g className={classes.faSecondary} fill={"currentColor"}>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m387.42 84.261h21.883c8.284 0 15-6.716 15-15s-6.716-15-15-15h-21.883c-8.284 0-15 6.716-15 15s6.716 15 15 15z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m442.21 138.521c8.284 0 15-6.716 15-15v-21.883c0-8.284-6.716-15-15-15s-15 6.716-15 15v21.883c0 8.285 6.716 15 15 15z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m497 54.261h-21.883c-8.284 0-15 6.716-15 15s6.716 15 15 15h21.883c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m442.21 51.883c8.284 0 15-6.716 15-15v-21.883c0-8.284-6.716-15-15-15s-15 6.716-15 15v21.883c0 8.284 6.716 15 15 15z"/>

                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m32.47 74.261h-17.47c-8.284 0-15 6.716-15 15s6.716 15 15 15h17.47c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m78.742 137.58v-17.47c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.47c0 8.284 6.716 15 15 15s15-6.716 15-15z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m95.013 104.261h17.47c8.284 0 15-6.716 15-15s-6.716-15-15-15h-17.47c-8.284 0-15 6.716-15 15s6.716 15 15 15z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m63.742 73.412c8.284 0 15-6.716 15-15v-17.47c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.47c0 8.284 6.716 15 15 15z"/>
                                </g>
                            </svg>
                            <span className={classes.linkText}>{content.introduction}</span>
                        </Link>
                    </li>

                    <li className={classes.navItem}>
                        <Link to="whatIDo"
                              activeClass={classes.active}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className={classes.navLink}>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="space-shuttle"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                                className="svg-inline--fa fa-space-shuttle fa-w-20 fa-5x"
                            >
                                <g className={classes.faPrimary}>
                                    <path
                                        fill={"currentColor"}
                                        xmlns="http://www.w3.org/2000/svg"
                                          d="m130.239 138.268-44.358 3.427c-12.343.954-23.336 7.423-30.162 17.748l-51.157 77.372c-5.177 7.83-6 17.629-2.203 26.213 3.798 8.584 11.603 14.566 20.878 16.003l40.615 6.29c9.501-50.42 32.245-100.716 66.387-147.053z"/>
                                    <path fill={"currentColor"}
                                        xmlns="http://www.w3.org/2000/svg"
                                          d="m226.682 448.151 6.291 40.615c1.437 9.275 7.419 17.08 16.002 20.877 3.571 1.58 7.351 2.36 11.112 2.36 5.283 0 10.529-1.539 15.102-4.563l77.374-51.156c10.325-6.827 16.794-17.821 17.746-30.162l3.427-44.358c-46.338 34.143-96.633 56.887-147.054 66.387z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          fill={"currentColor"}
                                          d="m211.407 420c1.41 0 2.828-.116 4.243-.352 21.124-3.532 41.484-9.482 60.906-17.27l-166.93-166.93c-7.788 19.421-13.738 39.781-17.27 60.906-1.392 8.327 1.401 16.81 7.37 22.78l93.144 93.144c4.956 4.955 11.645 7.722 18.537 7.722z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          fill={"currentColor"}
                                          d="m471.178 227.003c40.849-78.974 42.362-162.43 40.227-201.57-.731-13.411-11.423-24.103-24.835-24.834-6.373-.348-13.926-.599-22.439-.599-43.766 0-113.017 6.629-179.131 40.826-52.542 27.177-121.439 87.018-162.087 165.66.48.375.949.773 1.391 1.215l180 180c.442.442.839.91 1.214 1.39 78.642-40.649 138.483-109.546 165.66-162.088zm-173.48-118.763c29.241-29.241 76.822-29.244 106.065 0 14.166 14.165 21.967 33 21.967 53.033s-7.801 38.868-21.967 53.033c-14.619 14.619-33.829 21.93-53.032 21.932-19.209.001-38.41-7.309-53.033-21.932-14.166-14.165-21.968-33-21.968-53.033s7.802-38.868 21.968-53.033z"/>
                                </g>
                                <g className={classes.faSecondary}>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          fill={"currentColor"}
                                          d="m318.911 193.092c17.545 17.545 46.095 17.546 63.64 0 8.499-8.5 13.18-19.8 13.18-31.82s-4.681-23.32-13.18-31.819c-8.772-8.773-20.296-13.159-31.82-13.159-11.523 0-23.047 4.386-31.819 13.159-8.499 8.499-13.181 19.799-13.181 31.819s4.681 23.321 13.18 31.82z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          fill={"currentColor"}
                                          d="m15.305 421.938c3.839 0 7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0l-48.973 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.607 4.394z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          fill={"currentColor"}
                                          d="m119.765 392.239c-5.857-5.858-15.355-5.858-21.213 0l-94.155 94.155c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.393 10.607 4.393s7.678-1.464 10.606-4.394l94.154-94.154c5.859-5.858 5.859-15.355.001-21.213z"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          fill={"currentColor"}
                                          d="m143.432 437.12-48.972 48.973c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l48.973-48.973c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0z"/>

                                </g>
                            </svg>
                            <span className={classes.linkText}>{content.whatIDo}</span>
                        </Link>
                    </li>

                    <li className={classes.navItem}>
                        <Link to="skills"
                              activeClass={classes.active}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className={classes.navLink}>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="space-shuttle"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                                className="svg-inline--fa fa-space-shuttle fa-w-20 fa-5x"
                            >
                                <g className={classes.faSecondary} fill={"currentColor"}>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="M256,181c-41.353,0-75,33.647-75,75c0,41.353,33.647,75,75,75c41.353,0,75-33.647,75-75C331,214.647,297.353,181,256,181z    "/>
                                </g>
                                <g className={classes.faPrimary} fill={"currentColor"}>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="M512,298.305v-84.609l-69.408-13.667c-3.794-12.612-8.833-24.771-15.103-36.343l38.73-58.096l-59.81-59.81l-58.096,38.73    c-11.572-6.27-23.73-11.309-36.343-15.103L298.305,0h-84.609l-13.667,69.408c-12.612,3.794-24.771,8.833-36.343,15.103    L105.59,45.78l-59.81,59.81l38.73,58.096c-6.27,11.572-11.309,23.73-15.103,36.343L0,213.695v84.609l69.408,13.667    c3.794,12.612,8.833,24.771,15.103,36.343L45.78,406.41l59.81,59.81l58.096-38.73c11.572,6.27,23.73,11.309,36.343,15.103    L213.695,512h84.609l13.667-69.408c12.612-3.794,24.771-8.833,36.343-15.103l58.096,38.73l59.81-59.81l-38.73-58.096    c6.27-11.572,11.309-23.73,15.103-36.343L512,298.305z M256,361c-57.891,0-105-47.109-105-105s47.109-105,105-105    s105,47.109,105,105S313.891,361,256,361z"/>
                                </g>

                            </svg>
                            <span className={classes.linkText}>{content.skills}</span>
                        </Link>
                    </li>

                    <li className={classes.navItem}>
                        <Link to="exp"
                              activeClass={classes.active}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className={classes.navLink}>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="alien-monster"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                                className="svg-inline--fa fa-alien-monster fa-w-18 fa-9x"
                            >
                                <g className={classes.faSecondary} fill={"currentColor"}>
                                    <path xmlns="http://www.w3.org/2000/svg" d="m211 240h90v30h-90zm0 0"/>
                                </g>
                                <g className={classes.faPrimary} fill={"currentColor"}>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m415.378906 270h-84.378906v15c0 8.289062-6.710938 15-15 15h-120c-8.289062 0-15-6.710938-15-15v-15h-84.378906c-19.394532 0-36.546875-12.363281-42.6875-30.761719l-53.933594-161.828125v327.589844c0 24.8125 20.1875 45 45 45h422c24.8125 0 45-20.1875 45-45v-327.578125l-53.9375 161.816406c-6.136719 18.398438-23.289062 30.761719-42.683594 30.761719zm0 0"/>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="m316 0h-120c-24.8125 0-45 20.1875-45 45v15h-125.191406l56.574218 169.746094c2.050782 6.136718 7.777344 10.253906 14.238282 10.253906h84.378906v-15c0-8.289062 6.710938-15 15-15h120c8.289062 0 15 6.710938 15 15v15h84.378906c6.460938 0 12.1875-4.117188 14.238282-10.253906l56.578124-169.746094h-125.195312v-15c0-24.8125-20.1875-45-45-45zm-135 60v-15c0-8.277344 6.722656-15 15-15h120c8.277344 0 15 6.722656 15 15v15zm0 0"/>

                                </g>
                            </svg>
                            <span className={classes.linkText}>{content.education}</span>
                        </Link>
                    </li>

                    <li className={classes.navItem}>
                        <Link to={"featuredProjects"}
                              activeClass={classes.active}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className={classes.navLink}>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="space-station-moon-alt"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                className="svg-inline--fa fa-space-station-moon-alt fa-w-16 fa-5x"
                            >
                                <g className="fa-group">
                                    <path
                                        fill="currentColor"
                                        d="M501.70312,224H448V160H368V96h48V66.67383A246.86934,246.86934,0,0,0,256,8C119.03125,8,8,119.0332,8,256a250.017,250.017,0,0,0,1.72656,28.26562C81.19531,306.76953,165.47656,320,256,320s174.80469-13.23047,246.27344-35.73438A250.017,250.017,0,0,0,504,256,248.44936,248.44936,0,0,0,501.70312,224ZM192,240a80,80,0,1,1,80-80A80.00021,80.00021,0,0,1,192,240ZM384,343.13867A940.33806,940.33806,0,0,1,256,352c-87.34375,0-168.71094-11.46094-239.28906-31.73633C45.05859,426.01953,141.29688,504,256,504a247.45808,247.45808,0,0,0,192-91.0918V384H384Z"
                                        className={classes.faPrimary}
                                        />
                                    <path
                                        fill="currentColor"
                                        d="M256,320c-90.52344,0-174.80469-13.23047-246.27344-35.73438a246.11376,246.11376,0,0,0,6.98438,35.998C87.28906,340.53906,168.65625,352,256,352s168.71094-11.46094,239.28906-31.73633a246.11376,246.11376,0,0,0,6.98438-35.998C430.80469,306.76953,346.52344,320,256,320Zm-64-80a80,80,0,1,0-80-80A80.00021,80.00021,0,0,0,192,240Zm0-104a24,24,0,1,1-24,24A23.99993,23.99993,0,0,1,192,136Z"
                                        className={classes.faSecondary}
                                        />
                                </g>
                            </svg>
                            <span className={classes.linkText}>{content.featuredProjects}</span>
                        </Link>
                    </li>

                    <li className={classes.navItem}>
                        <Link to="contact"
                              activeClass={classes.active}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className={classes.navLink}>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="space-shuttle"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                                className="svg-inline--fa fa-space-shuttle fa-w-20 fa-5x"
                            >
                                <g xmlns="http://www.w3.org/2000/svg">
                                    <g className={classes.faPrimary} fill={"currentColor"}>

                                        <path d="M4.51,86.721C1.703,92.588,0,99.073,0,106v300c0,6.923,1.701,13.409,4.506,19.274L173.789,256L4.51,86.721z"/>
                                        <path d="M507.496,86.728L338.213,256.002L507.49,425.279c2.807-5.867,4.51-12.352,4.51-19.279V106    C512,99.077,510.301,92.593,507.496,86.728z"/>
                                    </g>
                                    <g className={classes.faPrimary} fill={"currentColor"}>
                                        <path d="M467,61H45c-6.927,0-13.412,1.703-19.279,4.51L255,294.789l51.389-49.387c0,0,0.004-0.005,0.005-0.007    c0.001-0.002,0.005-0.004,0.005-0.004L486.286,65.514C480.418,62.705,473.929,61,467,61z"/>
                                        <path d="M317.002,277.213l-51.396,49.393c-2.93,2.93-6.768,4.395-10.605,4.395s-7.676-1.465-10.605-4.395L195,277.211    L25.714,446.486C31.582,449.295,38.071,451,45,451h422c6.927,0,13.412-1.703,19.279-4.51L317.002,277.213z"/>
                                    </g>
                                </g>
                            </svg>
                            <span className={classes.linkText}>{content.contact}</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}