const metalGray = "rgb(204, 214, 246)";
const softGray = "#AAABB8";
const lightPink = "#ff7eee";
const darkPink = "rgb(223,73,166)";
const lightBlue = "#464866";
const darkBlue = "rgb(37,39,77)";
const digitalCyan = "rgb(100, 255, 218)";
const black = "rgb(60,72,88)";
const textWhite = "rgb(220,220,220)";
const textLight = "rgb(168, 178, 209)";

export {metalGray, softGray, darkBlue, darkPink, digitalCyan, lightBlue, textLight, lightPink, black, textWhite}