/*eslint-disable*/
import React, {useContext} from "react";
// react components for routing our app without refresh
import {Link} from "react-router-dom";
import classNames from "classnames";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";


// @material-ui/icons
import TranslateIcon from '@material-ui/icons/Translate';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import en from "assets/img/flags/united-kingdom.svg";
import jp from "assets/img/flags/japan(1).svg";
import {LanguageContext} from "../../languages/Language";

import resume from "assets/pdf/Tuan-Anh Bach Resume September.pdf"

const useStyles = makeStyles(styles);

export default function HeaderLinks() {
    const classes = useStyles();
    const content = useContext(LanguageContext).dictionary.headerContent;
    const userLanguageChange = useContext(LanguageContext).userLanguageChange;

    return (
        <List className={classes.list}>

            <ListItem className={classes.listItem}>
                <Link to="/journey" className={classes.navLink} color="transparent">{content.journey}</Link>
                <Link to="/projects" className={classes.navLink} color="transparent">{content.archive}</Link>
            </ListItem>

            <ListItem className={classes.listItem}>
                <a href={resume} className={classNames(classes.navLink)} color="transparent">{content.resume}</a>
            </ListItem>

            <ListItem className={classes.listItem}>
                <CustomDropdown
                    noLiPadding
                    buttonText={content.language}
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={TranslateIcon}
                    dropdownList={[
                        <div
                            className={classes.dropdownLink}
                            onClick={() => {
                                userLanguageChange("en")
                            }}
                        >
                            <img src={en} alt={"flag"}/>
                            English
                        </div>,
                        <div
                            className={classes.dropdownLink}
                            onClick={() => {
                                userLanguageChange("jp")
                            }}
                        >
                            <img src={jp} alt={"flag"}/>
                            日本語
                        </div>,
                    ]}
                />
            </ListItem>

            <ListItem className={classes.listItem}>
                <Tooltip
                    id="linkedin"
                    title="LinkedIn"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        href="https://www.linkedin.com/in/tuananh-bach/"
                        target="_blank"
                        color="transparent"
                        className={classes.navLink}
                    >
                        <i className={classNames("fab fa-linkedin", classes.socialIcons)}/>
                    </Button>
                </Tooltip>
            </ListItem>

            <ListItem className={classes.listItem}>
                <Tooltip
                    id="github"
                    title="GitHub"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        href="https://github.com/Trukibouge"
                        target="_blank"
                        color="transparent"
                        className={classes.navLink}
                    >
                        <i className={classNames("fab fa-github", classes.socialIcons)}/>
                    </Button>
                </Tooltip>
            </ListItem>

        </List>
  );
}
