import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';

import React, {useContext} from "react";

import classNames from "classnames";

import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import styles from "../../../assets/jss/material-kit-react/views/homePageSections/timeline.js";
import 'react-vertical-timeline-component/style.min.css';

import {makeStyles} from "@material-ui/core/styles";
import Badge from '../../../components/Badge/Badge';

import {LanguageContext} from "languages/Language";

const useStyles = makeStyles(styles);

export default function Timeline({id}){
    const classes = useStyles();
    const content = useContext(LanguageContext).dictionary.timeline;
    return(
        <div className={classes.container} id={id}>
            <div className={classes.blockTitle}>
                {content.categoryName}
            </div>
           <VerticalTimeline>
               {content.work.map((timelineElementContent) => {
                   return(
                       <VerticalTimelineElement
                           className={timelineElementContent.important ? classNames(classes.verticalTimelineElement, classes.work, classes.major) : classNames(classes.verticalTimelineElement, classes.work)}
                           contentArrowStyle={ timelineElementContent.important ? {borderRight: '7px solid #25274D'} : {}}
                           iconStyle={{ background: '#2E9CCA', color: '#fff' }}
                           icon={<WorkIcon />}
                           date={timelineElementContent.date}
                       >
                           <h3 className={classes.title}>{timelineElementContent.title}</h3>
                           <h4 className={classes.subtitle}>{timelineElementContent.company}</h4>
                           <h4 className={classes.subtitle}>{timelineElementContent.location}</h4>
                           <p>
                           <i className="fa fa-key"/> {timelineElementContent.skills}
                           </p>
                           <p>
                               <i className="fa fa-flask"/> {timelineElementContent.tech}
                           </p>
                           <ul>
                               {timelineElementContent.details.map((detail) => {
                                   return(
                                       <li >{detail}</li>
                                   )
                               })}
                           </ul>
                           <p>
                               {timelineElementContent.badges.map((badge) => {
                                   return(<Badge>{badge}</Badge>)
                               })}
                           </p>
                           <h3 className = {classes.bottomRight}>
                               {timelineElementContent.important && <div>{content.common.majorText}</div>}
                           </h3>

                       </VerticalTimelineElement>
                   )
                   }
               )}

               {content.education.map((timelineElementContent) => {
                       return(
                           <VerticalTimelineElement
                               className={timelineElementContent.important ? classNames(classes.verticalTimelineElement, classes.education, classes.major) : classNames(classes.verticalTimelineElement, classes.education)}
                               contentArrowStyle={ timelineElementContent.important ? {borderRight: '7px solid #25274D'} : {}}
                               iconStyle={{ background: 'rgb(233,30,99)', color: '#fff' }}
                               icon={<SchoolIcon />}
                               date={timelineElementContent.date}
                           >
                               <h3 className={classes.title}>{timelineElementContent.title}</h3>
                               <h4 className={classes.subtitle}>{timelineElementContent.school}</h4>
                               <h4 className={classes.subtitle}>{timelineElementContent.location}</h4>
                               {timelineElementContent.content.map((text) => {
                                   return(
                                       <p>{text}</p>
                                   )
                               })}
                               {timelineElementContent.details.length > 0 &&
                                   timelineElementContent.details.map((detail) => {
                                   return(
                                       <div>
                                           <br/>
                                           <div className={classes.details}>{detail}</div>
                                       </div>
                                   )
                               })}
                               <h3 className = {classes.bottomRight}>
                                   {timelineElementContent.important && <div>{content.common.majorText}</div>}
                               </h3>
                           </VerticalTimelineElement>
                       )
                   })
               }

               {content.school.map((timelineElementContent) => {
                   return(
                       <VerticalTimelineElement
                           className={timelineElementContent.important ? classNames(classes.verticalTimelineElement, classes.school, classes.major) : classNames(classes.verticalTimelineElement, classes.school)}
                           contentArrowStyle={ timelineElementContent.important ? {borderRight: '7px solid #25274D'} : {}}
                           iconStyle={{ background: '#45a508', color: '#fff' }}
                           icon={<LocationCityIcon />}
                           date={timelineElementContent.date}
                       >
                           <h3 className={classes.title}>{timelineElementContent.title}</h3>
                           <h4 className={classes.subtitle}>{timelineElementContent.school}</h4>
                           <h4 className={classes.subtitle}>{timelineElementContent.location}</h4>
                           {timelineElementContent.content.map((text) => {
                               return(
                                   <p>{text}</p>
                               )
                           })}
                           {timelineElementContent.details.length > 0 &&
                           timelineElementContent.details.map((detail) => {
                               return(
                                   <div>
                                       <br/>
                                       <div className={classes.details}>{detail}</div>
                                   </div>
                               )
                           })}
                           <h3 className = {classes.bottomRight}>
                               {timelineElementContent.important && <div>{content.common.majorText}</div>}
                           </h3>
                       </VerticalTimelineElement>
                   )
               })
               }
        </VerticalTimeline>
        </div>
        )
}