const headerContent = {
    en: {
        journey: "Journey",
        resume: "Resume Download",
        archive: "Project Archive",
        language: "Language",
    },
    jp: {
        journey: "ストーリー",
        resume: "履歴書ダウンロード",
        archive: "プロジェクトアーカイブ",
        language: "言語設定",
    }
};

export default headerContent;