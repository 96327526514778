import React from "react";

const timelineContent = {
        en: {
            categoryName: "Education & Experience",
            common: {
                majorText: "Notable"
            },
            work:
                [
                    {
                        important: false,
                        tech: "ReactJS | NestJS | Vercel | WordPress | InVision",
                        badges: ["TypeScript", "SCSS", "JSS", "PHP"],
                        date: "May 2020 - September 2020",
                        title: "Freelance Web Engineer",
                        company: "Self-employed",
                        location: "Aquitaine, France",
                        skills: "Project Management, Team Leading, Requirement Analysis, Visual Design, Implementation, Wireframing, User Testing",
                        details: [
                            "Led the requirement specification, participated in the design process and development of a web application for a Japanese France inbound tourism company using React (TypeScript) on top of a headless CMS installation.",
                            "Designed and created the bilingual website of a Japanese marketing company using WordPress CMS with Elementor and custom PHP snippets."
                        ],
                    },

                    {
                        important: true,
                        tech: ".NET Core | .NET Framework | IBM Db2 | Microsoft SQL Server",
                        badges: ["C#", "SQL"],
                        date: "March 2019 - August 2019",
                        title: "Software Engineer (Internship)",
                        company: "Daimler Trucks Asia, Mitsubishi FUSO, Pabco Co. Ltd.",
                        location: "Kanagawa, Japan",
                        skills: "Process Analysis, Project Management, Automation, Process Improvement, Requirement Analysis, Software Design, Implementation, User Testing",
                        details: [
                            "Managed, developed and implemented two process automation and digitization projects. Includes scheduling, analyzing and negotiating Japanese clients' needs and maintaining technical documentation.",
                            "Reduced target processes' length by 1.64 FTEs (85% of initial length). Approximate process cost savings of 25 000$/year.",
                            "Performed database administration, programming and performance tuning.",
                            "Developed RPA Native Applications, Windows Services, .NET Web Applications"
                        ],

                    },

                    {
                        important: true,
                        tech: "LoraWAN | STM32 | MySQL | PHP | BootStrap 4 | jQuery",
                        badges: ["C++", "HTML", "CSS"],
                        date: "January 2018 - February 2019",
                        title: "Project Manager",
                        company: "M-GaTE",
                        location: "Provence, France",
                        skills: "Project Management, Team Leading, Solution Design, Post-Sales Engineering",
                        details:[
                            "Conducted customer meetings and requirements analysis. ",
                            "Drafted technical specifications.",
                            "Defined and described solution architectures.",
                            "Led small teams of engineering graduates to complete projects.",
                            "Supervised the design and development of IoT hardware and its web service, connected using LoRaWaN protocol and linked through a third party data analytics service.",
                            "Successfully managed two projects within budget and deadline, having a ~15,000€ cumulative value."
                        ]
                    },

                    {
                        important: false,
                        tech: "InVision | Adobe Photoshop",
                        badges: ["SQL"],
                        date: "January 2018 - February 2018",
                        title: "UX Designer (Internship)",
                        company: "EMSPROTO",
                        location: "Bordeaux, France",
                        skills: "Visual Design, Prototyping, Wireframing, Database Design",
                        details: [
                            "Designed and created the prototype of an online custom cable assembly editor and a component search interface.",
                            "Designed a relational database to support the custom cable assembly editor."
                        ]
                    }
                ],

            education: [
                {
                    important: false,
                    date: "2020",
                    title: "Special Research Student",
                    school: "Tohoku University",
                    location: "Miyagi, Japan (Remote)",
                    content: ["Research Field: Human Computer Interaction"],
                    details: ["Research Topic: Heterogeneous Data Visualization and Interaction using Virtual Reality for Decision Support Systems in Vehicle Routing Optimization."],

                },
                {
                    important: true,
                    date: "2017 - present",
                    title: "Master of Science in Engineering (Diplôme d'Ingénieur), Computer Science and Microelectronics",
                    school: "Ecole des Mines de Saint-Etienne",
                    location: "Provence, France",
                    content: ["Computer Science, Microelectronics (General Education)",
                        "Native Applications, Mobile Applications, Web Development, Database Design, Discrete Simulation & Optimization (Specialization)",
                        "Information Technology & Supply Chain, Supply Chain Management, Data Science, Linear Optimization, RFID Technology (Electives)"],
                    details: ["1st year GPA: 3.65", "2nd year GPA: 3.94"]

                },
                {
                    important: false,
                    date: "2015 - 2017",
                    title: "Preparatory Class to the Grandes Ecoles (CPGE), Physics and Science in Engineering",
                    school: "CPGE Lycée Gay-Lussac",
                    location: "Limousin, France",
                    content: ["Mathematics, Physics, Chemistry, Mechanical Engineering",
                        "Graduation Research Topic: Maximizing solid-propellant rocket thrust through changes to the combustion chamber"],
                    details: []
                },
            ],
            school:[
                {
                    important: true,
                    date: "2014 - 2015",
                    title: "AFS Exchange Student (Humanities and Social Science)",
                    school: "Niigata Commercial High School",
                    location: "Niigata, Japan",
                    content: ["Independently initiated year-long exchange to a Japanese high school, with no prior fluency of Japanese."],
                    details: []
                },
                {
                    important: false,
                    date: "2011 - 2015",
                    title: "Scientific Baccalauréat (Sciences & Information Technology)",
                    school: "Périgueux Saint-Joseph High School",
                    location: "Aquitaine, France",
                    content: ["Graduation project: educative video game about nature (Python)"],
                    details: []
                },
            ]
        },


    jp: {
        categoryName: "履歴",
        common: {
            majorText: "重要"
        },
        work:
            [
                {
                    important: false,
                    tech: "ReactJS | NestJS | Vercel | WordPress | InVision",
                    badges: ["TypeScript", "SCSS", "JSS", "PHP"],
                    date: "2020年5月～2020年9月",
                    title: "ウエブエンジニア",
                    company: "フリーランサー",
                    location: "アキテーヌ地域圏、フランス",
                    skills: "プロジェクト管理、チームリーディング、要件分析、ビジュアルデザイン、実装、ワイヤーフレーム作成、ユーザーテスト",
                    details: [
                        "日本のマーケティング会社のバイリンガルサイトをデザイン・制作。",
                        "日仏のインバウンド観光会社のWebアプリの要件定義、設計、構築に参画。"
                    ],
                },

                {
                    important: true,
                    tech: ".NET Core | .NET Framework | IBM Db2 | Microsoft SQL Server",
                    badges: ["C#", "SQL"],
                    date: "2019年3月～2019年8月",
                    title: "ソフトエンジニア(インターシップ)",
                    company: "ダイムラートラックアジア, 三菱ふそうトラック・バス, 株式会社パブコ.",
                    location: "神奈川県、日本",
                    skills: "プロセス分析、プロジェクト管理、自動化、プロセス改善、要件分析、ソフトウェア設計、実装、ユーザーテスト",
                    details: [
                        "2つのプロセス自動化・デジタル化プロジェクトを管理、開発、実施。対象のニーズに合わせたスケジューリング、分析、交渉、技術文書の管理を含む。",
                        "対象工程の長さを1.64FTE（当初の85％）削減。約25,000ドル/年のプロセス原価削減。",
                        "データベース管理、プログラミング、パフォーマンスチューニングを行いました。",
                        "RPAネイティブアプリケーション、Windowsサービス、.NET Webアプリケーションの開発。"
                    ],

                },

                {
                    important: true,
                    tech: "LoraWAN | STM32 | MySQL | PHP | BootStrap 4 | jQuery",
                    badges: ["C++", "HTML", "CSS"],
                    date: "2018年1月～2019年2月",
                    title: "プロジェクトマネージャー",
                    company: "M-GaTE",
                    location: "プロヴァンス地域圏, フランス",
                    skills: "プロジェクト管理、チームリーディング、ソリューション設計、ポストセールスエンジニアリング",
                    details: [
                        "要件分析に向けて顧客との打ち合わせを実施。",
                        "技術仕様書を作成。",
                        "ソリューション設計を定義。",
                        "小チームを率いてプロジェクトを完成させました。",
                        "LoRaWaNを使用するIoTハードウェアとそのWebサービスの設計と開発を監督しました。",
                        "予算と期限内に2つのプロジェクトを成功裏に管理し、約15,000ユーロの収入増。"
                    ]
                },

                {
                    important: false,
                    tech: "InVision | Adobe Photoshop",
                    badges: ["SQL"],
                    date: "2018年1月～2018年2月",
                    title: "UXデザイナー（インターンシップ)",
                    company: "EMSPROTO",
                    location: "アキテーヌ地域圏、フランス",
                    skills: "ビジュアルデザイン、プロトタイピング、ワイヤーフレーム作成、データベースデザイン",
                    details: [
                        "ケーブルアセンブリエディタとコンポーネント検索インターフェースのプロトタイプを設計し、作成。",
                        "カスタムケーブルアセンブリエディタをサポートするためのリレーショナルデータベースを設計。"
                    ]
                }
            ],

        education: [
            {
                important: false,
                date: "2020年",
                title: "特別研究生",
                school: "東北大学",
                location: "宮城県、日本 (リモート)",
                content: ["研究分野: ヒューマンコンピュータインタラクション"],
                details: ["研究テーマ: Heterogeneous Data Visualization and Interaction using Virtual Reality for Decision Support Systems in Vehicle Routing Optimization.　(自動車経路最適化における意思決定支援システムのVR向け異種データの可視化とインタラクション"],

            },
            {
                important: true,
                date: "2017年～現在",
                title: "電子工学・計算機科学専攻(修士号)",
                school: "サンテティエンヌミンヌ国立高等鉱業学校",
                location: "プロヴァンス地域圏, フランス",
                content: ["コンピュータサイエンス、マイクロエレクトロニクス (一般教養)",
                    "ネイティブアプリケーション、モバイルアプリケーション、Web開発、データベース設計、離散型シミュレーションと最適化 (専門)",
                    "情報技術とサプライチェーン、供給連鎖管理、データサイエンス、線型計画法、RFID技術（選択科目)"],
                details: ["Master　１　GPA: 3.65", "Master　２ GPA: 3.94"]

            },

            {
                important: false,
                date: "2015年～2017年",
                title: "グランゼコール準備級, 物理と工学(学士号)",
                school: "CPGE Lycée Gay-Lussac",
                location: "リムーザン地域圏、フランス",
                content: ["数学、物理学、化学、機械工学",
                    "卒業研究；「燃焼室の変化による固体ロケットの推力の最大化」"],
                details: []
            },
        ],
        school: [
            {
                important: true,
                date: "2014年～2015年",
                title: "AFS留学生 (国際教養)",
                school: "新潟商業高等学校",
                location: "新潟県, 日本",
                content: ["日本の高校への10ヶ月の交換留学・ホームステイ"],
                details: []
            },
            {
                important: false,
                date: "2011 - 2015",
                title: "科学バカロレア（科学・情報工学)",
                school: "Périgueux Saint-Joseph高校",
                location: "アキテーヌ地域圏、フランス",
                content: ["卒業制作：自然をテーマにした教育用ゲーム（Python)"],
                details: []
            },
        ]
    },


}

export default timelineContent;