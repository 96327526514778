import {blockTitle, container, title} from "../../../material-kit-react";
import image from "../../../../img/scape.png";
import {black} from "assets/jss/material-kit-react/colors";
import * as colors from "../../colors";

const style = {
    section: {
        minHeight: "1080px",
        maxHeight: "1600px",
        overflow: "hidden",
        backgroundPosition: "bottom center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        margin: "150px auto 0",
        padding: "50px",
        border: "0",
        display: "flex",
        backgroundImage: "url(" + image + ")"
    },

    container:{
        ...container,
        margin: "-200px"
    },

    title: {
        ...title,
    },

    blockTitle: {
        ...blockTitle,
        counterIncrement: 'section 4',
        color: black,
    },

    description: {
        // margin: "1.071rem auto 0",
        fontSize: "20px",
        // maxWidth: "600px",
        color: black
    },

    boxed:{
        boxShadow: "rgba(2, 12, 27, 0.7) 0px 10px 30px -15px",
        transition: "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s",
        position: "relative",
        zIndex: "2",
        padding: "15px 15px 15px 15px",
        backgroundColor: "rgb(240,240,240,0.75)",
        fontSize: "18px",
        borderRadius: "3px",
        margin: "150px 20px"
    },

    marginAuto: {
        marginLeft: "auto !important",
        marginRight: "auto !important"
    },

    linkWrapper:{
        margin:"80px auto 10px",
        color: colors.black,
        display: "flex",
        justifyContent:"center",
        "& ul":{
            display:"inline-flex",
            listStyleType:"none",
            padding: "0",
            margin: "0",
            "& li":{
                marginRight:"20px"
            }
        },
    },

    "@media only screen and (max-width: 768px)": {
        linkWrapper:{
            margin: "40px auto 10px",
            "& ul": {
                display: "inline",
                "& li": {
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "0px"
                }
            }
        }
    },

    "@media only screen and (min-width: 768px)": {
        marginAuto: {
            marginTop: "80px"
        }
    }

}

export default style;