import {container, title} from "../../material-kit-react";
import * as colors from "assets/jss/material-kit-react/colors"
import {black} from "assets/jss/material-kit-react/colors"


const journeyStyle = {
    container: {
        ...container,
    },

    overlay: {
        "&:before": {
            background: "rgba(0,0,0,.5)",
            width: "100%",
            height: "100%",
            position: "absolute",
            content: "''",
            zIndex: "1"
        },
    },

    intro: {
        zIndex: "2"
    },

    linkWrapper: {
        display: "flex",
        position: "relative",
        boxAlign: "center",
        alignItems: "center",
        marginLeft: "-10px",
        marginTop: "10px",
        color: "rgb(204, 214, 246)",

        "& a": {
            padding: "10px",
            "& svg": {
                width: "22px",
                height: "22px",
                transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
                "&:hover": {
                    transform: "scale(1.2)",
                    color: colors.lightPink
                }
            }
        }
    },

    endLink: {
        margin: "80px auto 10px",
        color: colors.black,
        display: "flex",
        justifyContent: "center",
        "& ul": {
            display: "inline-flex",
            listStyleType: "none",
            padding: "0",
            margin: "0",
            "& li": {
                marginRight: "20px"
            }
        },
    },

    parallax: {
        minHeight: "1080px",
        display: "flex",
    },

    title: {
        ...title,
        margin: "0",
        marginTop: "0",
        marginBottom: "15px",
        fontSize: "44px",
        color: black,
    },

    description: {
        fontSize: "100%",
        color: colors.black,
        lineHeight:"1.45"
    },

    alternating:{
        "& $gridContainer":{
            justifyContent: "left",
            "&:nth-of-type(odd)":{
                justifyContent: "right"
            },
        }
    },

    gridContainer:{

    },

    boxed: {
        boxShadow: "rgba(2, 12, 27, 0.7) 0px 10px 30px -15px",
        transition: "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s",
        position: "relative",
        zIndex: "2",
        padding: "30px",
        backgroundColor: "rgb(240,240,240, 0.9)",
        fontSize: "18px",
        margin: "150px 20px"
    },

    "@media only screen and (max-width: 768px)": {
        endLink: {
            margin: "40px auto 10px",
            "& ul": {
                display: "inline",
                "& li": {
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "0px"
                }
            }
        },

        parallax:{
            height:"auto !important"
        }

    }
}

export default journeyStyle;