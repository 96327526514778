import React, {useContext} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-scroll";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import Parallax from "../../components/Parallax/Parallax.js";
import Footer from "../../components/Footer/Footer";

//Sections
import Introduction from "../../views/HomePage/Sections/Introduction";
import Timeline from "./Sections/Timeline";
import FeaturedProjects from "./Sections/FeaturedProjects";

import styles from "../../assets/jss/material-kit-react/views/homePage";
import LateralBar from "../../components/LateralBar/LateralBar";
import Skills from "./Sections/Skills";
import Contact from "./Sections/Contact";

import {LanguageContext} from "languages/Language";
import WhatIDo from "./Sections/whatIDo";

const useStyles = makeStyles(styles);
function HomePage(){
    const classes = useStyles();
    const content = useContext(LanguageContext).dictionary.homepageContent;
    return(
        <div>
            <Header
                color="transparent"
                rightLinks={<HeaderLinks/>}
                fixed = {true}
                changeColorOnScroll={{
                height: 700,
                color: "dark"
                }}
            />

            <LateralBar/>

            <Parallax medium filter image={require("assets/img/homePageBG.jpg")} className={classes.overlay}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={10}>
                        <h3>{content.catchPhrase}</h3>
                        <h1 className={classes.title}>
                            {content.name}
                        </h1>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <h3>
                                {content.introduction1}
                            </h3>
                            <h3>
                                {content.introduction2}
                            </h3>
                            <br/>
                            <Link to="contact"
                                  activeClass={classes.active}
                                  spy={true}
                                  smooth={true}
                                  offset={-70}
                                  duration={500}>
                                <Button
                                    color="primary"
                                    size="lg"
                                    href="mailto:tuananh.bach@etu.emse.fr"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fas fa-address-card"/>
                                    {content.contactButton}
                                </Button>
                            </Link>

                        </GridItem>
                    </GridContainer>
                </div>
        </Parallax>
        
        <div className={classNames(classes.main, classes.mainRaised)}>
            <Introduction id="intro"/>
            <WhatIDo id="whatIDo"/>
            <Skills id="skills"/>
            <Timeline id="exp"/>
            <FeaturedProjects id="featuredProjects"/>
            <Contact id="contact"/>
        </div>

        <Footer />
    </div>
    );
}

export default HomePage;