import React, {createContext, useState} from 'react';
import {dictionaryList} from "./Options";

export const LanguageContext = createContext({
    userLanguage: 'en',
    dictionary: dictionaryList.en,
    userLanguageChange: () => {
    },
    toggleLanguage: () => {
    }
})

export function LanguageProvider({children}) {
    const [userLanguage, setUserLanguage] = useState('en');

    const provider = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        userLanguageChange: (selected) => {
            // const newLanguage = languageOptions[selected] ? selected : "en";
            setUserLanguage(selected);
        },
        toggleLanguage: () => {
            setUserLanguage(userLanguage === 'en' ? 'jp' : 'en');
        }
    }
    return(
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    )
}