const introductionContent = {
    en: {
        title: "About me",
        firstParagraph: "Thank you very much for visiting my website! I am currently a Computer Science & Microelectronics Master's student at Mines Saint-Etienne. Having accumulated experience abroad and locally along the years, I came to enjoy being challenged working on technically demanding projects. In most of these projects, I had to combine design & development with Project Management, where customer relationship, planning, technology knowledge and language skills heavily contribute to their success.",

        secondParagraph: "As a software engineer, I want to bridge the gap between design, development and social intelligence. With experience in a variety of projects, be it building websites, web services, native applications or electronic systems; as well as fluency in three languages -French, English and Japanese-, my career goal is to keep tackling technical software or electronics challenges in an international environment where a strong understanding of diverse actors is needed. ",
        finisher: "By combining strong technical skills, the capacity and the curiosity to work and share with people of various nationalities, project management and emotional intelligence, I strive to become a versatile engineer able to fit in any kind of situation, in any country.",
        techIntroduction: "A few techs I've worked with recently:",
        learnMore: "Learn more about my journey.",
        journeyButton: "Journey"
    },

    jp: {
        title: "自己紹介",
        firstParagraph: "本ウェブサイトをご覧いただきありがとうございます。現在、サン＝テティエンヌ国立高等鉱業学校の計算機科学と電子工学の修士課程に在籍しています。長年にわたって海外や母国のフランスで経験を積んできたことで、高度な技術を要するプロジェクトでも楽しんで取り組むことが出来るようになりました。それらのプロジェクトの多くでは、設計と開発をプロジェクト管理に組み合わせるスキルが求められたほか、顧客との関係構築力、計画力、技術知識、言語スキルがプロジェクトの成功に大きく貢献しました。",

        secondParagraph: "今後もソフトウェアエンジニアとして、デザイン、開発、ソーシャルインテリジェンスの三つをつなぐ役割を果たしていきたいと思っています。ウェブサイト、SaaS、ネイティブアプリ、電子システムなど様々なプロジェクトの経験で得たことだけでなく、フランス語、英語、日本語の三カ国語に堪能であることを駆使し、多様な人物の理解が必要とされる国際的な環境で、ソフトウェアや電子の課題に取り組み続けることが私のキャリアプランとなっています。",

        finisher: "強い技術力、様々な国籍の人々と交流する能力と好奇心、プロジェクト管理の経験、感情的知性を組み合わせることで、どの国のどのような状況にも臨機応変かつ積極的に向き合い解決して適応できる多才なエンジニアになれるように努力しています。",
        techIntroduction: "最近使ったスタック：",
        learnMore: "今までの、自分のストーリーについてはこちらです",
        journeyButton: "Journey"
    }
}

export {introductionContent};