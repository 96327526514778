import * as colors from "assets/jss/material-kit-react/colors"

const dotNavStyle ={
    dotNav:{
        position: "fixed",
        right: "34px",
        zIndex: "2",
        top: "50%",
        // bottom:"auto",
        transform: "translateY(-25%)",

        "& ul": {
            listStyle: "none"
        },

        "& a": {
            display: "inline-block",
            cursor: "pointer",
            "&:after": {
                clear: "both",
                content: "",
                display: "table"
            },

            "& span": {
                float: "right",
                transform: "scale(.6)"
            },


            "&:hover":{
                "& span":{
                    opacity: "1",
                    transform: "scale(0.75)"
                }
            }
        },


    },
    dotContainer:{
        textAlign: "right"
    },
    active:{
        "& $dot":{
            background: colors.darkPink,
        },
    },
    dot:{
        position: "relative",
        top:"7px",
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        background: "rgb(240,240,240,.25)",
        transition: "transform .2s, background-color .5s",
        transformOrigin: "50% 50%"
    },
    dotLabel: {
        position: "relative",
        top: "3px",
        padding: ".4em .5em",
        color: colors.textWhite,
        fontSize: "1em",
        fontWeight: "500",
        textTransform: "Uppercase",
        opacity: "0",
        transition: "transform .2s, opacity .2s",
        transformOrigin: "100% 50%"
    },

    "@media only screen and (max-width: 768px)": {
        dotNav: {
            visibility: "hidden"
        }
    }
}

export default dotNavStyle;