import personal from "assets/img/projects/personalsite.png";
import pzdv from "assets/img/projects/concept1.png";
import shaken from "assets/img/projects/shaken.png";
import aes from "assets/img/projects/aes.png";
import grid from "assets/img/projects/grid.png";
import earthexplore from "assets/img/projects/earthexplore.png";
import pi from "assets/img/projects/pi.png";
import pokAppli from "assets/img/projects/pokappli.png"

import vhdl from "assets/pdf/Rapport_PCSN_redacted.pdf";
import bio from "assets/pdf/Biorobotics P3 Report.pdf";
import prbt1 from "assets/pdf/ProjetRobot1.pdf";
import prbt2 from "assets/pdf/ProjetRobot2.pdf"
import patisa from "assets/pdf/PatisaConsulting.pdf"
import uml from "assets/pdf/UMLReport.pdf"
import bdd from "assets/pdf/TruckBuildingValueChainDBProposal.pdf"

const projectTypes = {
    software: "sw",
    hardware: "hw",
    misc: "misc",
}

const projectData = {
    en: {
        categoryName: "Featured Projects",
        pzDv: {
            projectName: "Pz. Dv",
            description: "A T-RPG game group project set in a low fantasy, 1930s interwar inspired world, featuring an engaging story, captivating characters, vehicle-based tactical challenges, and a map+tileset editor. I am involved in game design, development, world-building and concept art drawing.",
            longDescription: "After the Weltkrieg, launched by the Empire of Feris against all of its neighbouring countries, ended with a coup putting an end to its military regime, the world is reclining in the utter destruction of this war. Alice, orphaned because of the Weltkrieg and living a miserable life in the poor country of Thracia, decides to join a merchant caravan albeit as a (***) in order to improve her and her village's living conditions. Travelling and peddling around the world, from the deserts of the Sultanate of Siwa to the mountainous city-state of Yamatai, she is confronted with intriguing cultures, but also starts to get wind of new unrest in Feris...",
        },

        personalWebsite: {
            projectName: "Personal Website",
            description: "The first iteration of my website, built in React using Material-UI framework. The project was born from the need to aggregate the information from my English resume and my Japanese CV (rirekishou) in a single place. The French, Japanese and Korean version of this site are coming soon!",
        },

        tagsys: {
            projectName: "TAGSYS RFID High-end Retail RFID False Positive Data Recognition Model Improvement Project",
            description: "Working with TAGSYS RFID as a team member, optimized a supervised Machine Learning model to process raw RFID antenna reading data from Chanel product batches to detect false positive reading anomalies and identify misplaced objects on a conveyor belt. Hit rate increased from 95% to 99%",
            techList: ["Python", "Panda", "Tensorflow"],
        },

        shaken: {
            projectName: "Pabco Co. Shaken STEP 2",
            description: "A project aiming to provide powerful automation tools for Daimler Truck Asia MTFBC Pabco. Co's Engineering and Quality departments. The initial goal was to cut 90% of the cost of a lengthy and tedious cross-departmental vehicle verification process. I intervened directly between the two departments to mediate their discussion, created a new optimized process flowchart as well as the necessary tools to accompany it, achieving a 85% reduction.",
        },

        qmTools: {
            projectName: "Pabco Co. Quality Management Approval Tools",
            description: "A native application aiming to empower MFBTC Pabco. Co's Quality Management department and streamline downstream data flow with other departments.",
        },

        minesweeper: {
            projectName: "4-Player Online Minesweeper",
            description: "A speed-oriented competitive Minesweeper game with up to 4 players, featuring an online scoreboard and basic social functions to flame your friends.",
        },

        pokappli: {
            projectName: "PokAppli: Pokémon Android Application",
            description: "A small Android Application project fetching geolocalized Pokémon data and linking them to a web service.",
        },

        reinforcement: {
            projectName: "Renewable Energy Grid Management using Reinforcement Learning",
            description: "A reinforcement learning simulation environment made to optimize the selling, buying and use of renewable energy contained in a power grid. The goal is to reach the best energy usage behaviour from a supplier perspective despite an unpredictable environment characterized by renewable energy sources and the behaviour and energy users. ",
            longDescription: "The model was progressively tuned to accomplish different objectives:  maximize energy selling, maximize the use of locally produced energy, and optimize the consumption of energy with a variable energy demand. The project was done in collaboration with another group, whose task was to offer energy data inputs predicted by a supervised learning algorithm.",
        },

        aes128:{
            projectName: "AES-128 Decryption Digital System Design",
            description: "A digital system built with combinatory and sequential elements to decipher messages encrypted using the Advanced Encryption Standard (128 bits) as described in FIPS 197. Performed simulations and validations using the VHDL hardware description language and ModelSim. ",
            longDescription: "",
        },

        stm32:{
            projectName: "Smartphone-controlled swarm robot microcontroller programming project",
            description: "Programmed a STM32 microcontroller linked to a motorized chassis in order satisfy predefined requirement specifications. The programming phase was preceded by a rigorous designing step. The robot had to be Bluetooth-compatible, so to be controllable by a smartphone, and had to be able to automatically park as a member of a swarm of robots communicating with the ZigBee protocol.",
            longDescription: "",
        },
        pi: {
            projectName: "Industrial Project: Wireless-to-Smartphone secured VLC pairing Project Management",
            description: "Led the pre-sales process of an industrial project ordered by the French Alternative Energies and Atomic Energy Commission (CEA Leti), in partnership with Mines Saint-Etienne.　The project’s goal was the development of a PoC for an authentication system to be used by IoT object linked to smartphones using visible light communications.",
            longDescription: "The pre-project consisted of customer consulting, requirement definition and detailed scope definition, in order for the development phase to proceed smoothly. The project’s goal was the development of a PoC for an authentication system to be used by IoT object linked to smartphones using visible light communication.",
        },

        earthExplore: {
            projectName: "EarthExplore website revamp",
            description: "The full revamp of Earth Explore website to a responsive, bilingual design. Earth Explore is one of the Japanese companies I worked for as a Freelancer during 2020 COVID lockdown."
        },

        tipe: {
            projectName: "Graduation Project: Maximizing solid-propellant rocket thrust through changes to the combustion chamber",
        },

        biorobotics: {
            projectName: "Assessment of speed without mechanical sensor through visual optic flow calculations"
        },

        probot1: {
            projectName: "PIC18-centered PCB design for remote control robot",
            description: "",
            longDescription: "",
        },

        probot2: {
            projectName: "PIC18-centered microcontroller programming for remote control robot",
            description: "",
            longDescription: "",
        },

        algoprog1: {
            projectName: "Road Intersection with two traffic lights simulation in C"
        },

        algoprog2: {
            projectName: "Typing prediction using hash tables in C++"
        },

        patisa: {
            projectName: "Full consulting report of a fictionnal RFID company"
        },

        uml: {
            projectName: "CLI Course registration & planning system"
        },

        bdd: {
            projectName: "Truck value chain processing Database Design"
        }
    },

    projectsCommonData: [
        {
            id: "personalWebsite",
            type: projectTypes.software,
            featured: true,
            date: "2020",
            techList: ["React", "JavaScript", "TypeScript", "Material-UI", "SASS", "JSS"],
            imgSrc: personal
        },
        {
            id: "earthExplore",
            type: projectTypes.software,
            featured: true,
            date: "2020",
            techList: ["WordPress", "PHP", "Elementor", "CSS"],
            imgSrc: earthexplore,
            link: "http://earthexplore.jp/"
        },
        {
            id: "shaken",
            type: projectTypes.software,
            featured: true,
            date: "2019",
            techList: ["C#", ".NET Core", ".NET Framework"],
            imgSrc: shaken,
        },
        {
            id: "tagsys",
            type: projectTypes.software,
            featured: false,
            date: "2020",
            techList: ["Python", "Panda", "Tensorflow"],
        },

        {
            id:"qmTools",
            type: projectTypes.software,
            featured: false,
            date: "2019",
            techList: ["C#", ".NET Framework"],
        },
        {
            id:"minesweeper",
            type: projectTypes.software,
            featured: false,
            date: "2019",
            techList: ["Java"],
            github: "https://github.com/Trukibouge/minesweeper",
        },

        {
            id: "reinforcement",
            type: projectTypes.software,
            featured: true,
            date: "2018",
            techList: ["Python", "Panda", "Tensorflow"],
            linkWrapper:"",
            imgSrc: grid,
            github: "https://github.com/Trukibouge/Energy-Management",
        },
        {
            id: "aes128",
            type: projectTypes.hardware,
            featured: true,
            date: "2018",
            techList: ["VHDL", "ModelSim"],
            linkWrapper:"",
            imgSrc: aes,
            github: "https://github.com/Trukibouge/VHDL-AES128",
            report: vhdl,
        },
        {
            id: "stm32",
            type: projectTypes.hardware,
            featured: false,
            date: "2018",
            techList: ["C++", "STM32", "STM32Cube"],
            linkWrapper: "",
            github: "https://github.com/Trukibouge/stm32swarm"
        },
        {
            id: "pi",
            featured: true,
            type: projectTypes.misc,
            date: "2019",
            techList: [""],
            linkWrapper: "",
            imgSrc: pi,
        },
        {
            id: "pzDv",
            type: projectTypes.software,
            featured: false,
            date: "2018",
            techList: ["DLang", "Github API"],
            imgSrc: pzdv
        },


        {
            id: "pokappli",
            type: projectTypes.software,
            featured: true,
            date: "2019",
            techList: ["TypeScript", "Vercel", "NestJS", "Kotlin", "Room", "Retrofit 2"],
            linkWrapper: "",
            imgSrc: pokAppli,
            github: "https://github.com/ISMIN-Design-Of-Mobile-Application/open-data-android-app-Isomorphismes"
        },
        {
            id: "tipe",
            featured: false,
            date: "2016",
            techList: ["Python"],
        },
        {
            id: "biorobotics",
            featured: false,
            date: "2017",
            techList: ["Matlab"],
            report: bio
        },
        {
            id: "probot1",
            featured: false,
            date: "2017",
            techList: ["C, MP LAB X", "Proteus"],
            report: prbt1
        },
        {
            id: "probot2",
            date: "2017",
            techList: ["C, MP LAB X"],
            report: prbt2
        },
        {
            id: "algoprog1",
            featured: false,
            date: "2017",
            techList: ["C"],
        },
        {
            id: "algoprog2",
            featured: false,
            date: "2017",
            techList: ["C"],
            github: "https://github.com/Trukibouge/Project-C---Le-retour"
        },

        {
            id: "patisa",
            date: "2018",
            techList: [""],
            report: patisa
        },
        {
            id: "uml",
            date: "2018",
            techList: ["C++, UML"],
            report: uml,
            github: "https://github.com/Trukibouge/Course-System"
        },
        {
            id: "bdd",
            date: "2019",
            techList: ["UML"],
            report: bdd,
        }


    ],

    jp: {
        categoryName: "注目のプロジェクト紹介",
        pzDv: {
            projectName: "Pz. Dv",
            description: "1930年代の戦間期に感化されたローファンタジーの世界を舞台にしたT-RPGゲームのグループのプロジェクト。複合的なストーリー、カリスマ性を持ったキャラクター、マップ+タイルセットエディタを特徴としています。ゲームデザイン、開発、コンセプトアートの制作に携わっています。",
        },

        personalWebsite: {
            projectName: "個人サイト",
            description: "Material-UIフレームワークを使用してReactで構築された個人サイトの最初のイテレーションです。このプロジェクトは、英語と日本語の履歴書の情報を一つの場所に集約することを目的としています。フランス語晩と韓国語版は近日公開予定です！",
        },

        tagsys: {
            projectName: "ＲＦＩＤ偽陽性データ認識人工知能モデル改善プロジェクト",
            description: "シャネル製品のバッチからの生のRFID読み取りデータを処理するための機械学習モデルを開発し、偽陽性読み取り異常を検出し、コンベアベルト上の未知の物体を識別する。 命中率が95%から99%に上昇",
            techList: ["Python", "Panda", "Tensorflow"],
        },

        shaken: {
            projectName: "車検　STEP 2 (プロセス改善)",
            description: "株式会社パブコの技術・品質部門に強力な自動化ツールを提供することを目的としたプロジェクト。当初の目標は、長くて退屈な部門間の車両検証プロセスのコストを90%削減することでした。私は、両部門間の話し合いを仲介するために直接介入し、新たに最適化されたプロセスフローチャートとそれに付随する必要なツールを作成し、85%の削減を達成しました。",
        },

        qmTools: {
            projectName: "品質管理承認ツール",
            description: "A native application aiming to empower MFBTC Pabco. Co's Quality Management department and streamline downstream data flow with other departments.",
        },

        minesweeper: {
            projectName: "4Pのオンラインマインスイーパ",
            description: "A speed-oriented competitive Minesweeper game with up to 4 players, featuring an online scoreboard and basic social functions to flame your friends.",
        },

        pokappli: {
            projectName: "PokAppli：ポケモンAndroidアプリ",
            description: "ポケモンのデータを収集してWebサービスと連携させるAndroidアプリの小型プロジェクト。",
        },

        reinforcement: {
            projectName: "強化学習を用いた再生可能エネルギーグリッド管理",
            description: "電力系統の再生可能エネルギーの販売、購入、利用を最適化するために作られた強化学習シミュレーション環境。目標は、再生可能エネルギー源の不安定性とエネルギー利用者の行動予測不可能な環境を乗り越え、供給の立場から最善のエネルギー利用行動に到達することでした。",
            longDescription: "The model was progressively tuned to accomplish different objectives:  maximize energy selling, maximize the use of locally produced energy, and optimize the consumption of energy with a variable energy demand. The project was done in collaboration with another group, whose task was to offer energy data inputs predicted by a supervised learning algorithm.",
        },

        aes128: {
            projectName: "AES-128復号デジタルシステムデザイン",
            description: "FIPS 197に記載されているAES-128で暗号化されたメッセージを解読するために、順序回路と組合せ論理回路を用いて構築されたデジタルシステム。ハードウェア記述言語VHDLとModelSimを用いてシミュレーションと検証をしました。",
            longDescription: "",
        },

        stm32: {
            projectName: "集団行動ロボットSTM32マイコンプログラミング",
            description: "Programmed a STM32 microcontroller linked to a motorized chassis in order satisfy predefined requirement specifications. The programming phase was preceded by a rigorous designing step. The robot had to be Bluetooth-compatible, so to be controllable by a smartphone, and had to be able to automatically park as a member of a swarm of robots communicating with the ZigBee protocol.",
            longDescription: "",
        },


        pi: {
            projectName: "Wireless-to-Smartphone VLCの確保したペアリング 産業用プロジェクトマネジメント",
            description: "フランス原子力・代替エネルギー（CEA Leti）が発注した産業用プロジェクトのプリセールスプロセスを、Mines Saint-Etienneと共同で主導しました。このプロジェクトの目的は、スマートフォンと連携したIoTオブジェクトが可視光通信を利用して動作する認証システムのPoCを開発することでした。",
            longDescription: "The pre-project consisted of customer consulting, requirement definition and detailed scope definition, in order for the development phase to proceed smoothly. The project’s goal was the development of a PoC for an authentication system to be used by IoT object linked to smartphones using visible light communications",
        },

        earthExplore: {
            projectName: "EarthExploreホームページ改良",
            description: "Earth Exploreのウェブサイトをレスポンシブでバイリンガルなデザインに全面刷新。"
        },

        tipe: {
            projectName: "卒業プロジェクト：燃焼室の変更による固体ロケットの推力の最大化",
        },

        biorobotics: {
            projectName: "オプティカルフロー計算による機械的センサーのない速度の評価"
        },

        probot1: {
            projectName: "リモートコントロールロボット用PIC18基板設計",
            description: "",
            longDescription: "",
        },

        probot2: {
            projectName: "リモートコントロールロボットのためのPIC18マイコンプログラミング",
            description: "",
            longDescription: "",
        },

        algoprog1: {
            projectName: "2つの信号機を持つ道路交差点のシミュレーション"
        },

        algoprog2: {
            projectName: "ハッシュテーブルを用いた入力予測"
        },

        patisa: {
            projectName: "RFID企業のコンサルティングレポート"
        },

        uml: {
            projectName: "CLI 講座登録・企画システム"
        },

        bdd: {
            projectName: "トラックのバリューチェーン加工のデータベース設計"
        }
    },
}


export {projectData};