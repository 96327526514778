const whatIDoContent = {
    en:{
        title: "What I Do",

        subtitle: "With a double major-degree in Computer Science and Microelectronics, in addition to practical experience working as a team member or as a project lead whether with fellow Frenchmen and women or in an international environment, I can contribute to a wide variety of technical projects.",

        software: {
            title: "Software Engineering",
            description: "Be it in Agile Scrum or V-model, from embedded systems to web apps, my core expertise revolves around analyzing and discussing a customer's needs, designing a solution, and creating value without superfluous features and within time. The thread separating an over-engineered product from an under-performing solution is thin but incredibly rewarding to walk on. I desire to create innovative products that precisely answer to demand and beyond without wasted resources.",
        },

        fullstack: {
            title: "Fullstack Web Development",
            description: "Using new or older stacks alike, such as HTML + CSS with PHP or React coupled with Node.js and preprocessors or tools such as JSS and SASS, I appreciate creating visually pleasing, responsive interfaces and RESTful services. These days, I have taken an interest in headless CMS, in order to combine the relative intuitiveness of CMS for non-technical users with the power of recent front-end libraries and frameworks.",
        },

        experience: {
            title: "Experience Design",
            description: "Being a daily software user as well as a game enthusiast, I can't begin to recall how many times I've despaired seeing the potential of a product ruined because of poor design decisions. I strongly believe that intuitive user experience is the key that differentiates a good product with an exceptional one, and I strive to create products that not only perform but also look and feel modern with no gimmicks.",
        },

        process: {
            title: "Process Automation & Kaizen",
            // description: "It's no exaggeration to say that I've been shocked to witness SMEs and corporation's subsidiaries still relying on tedious and redundant operations in their processes, with employees perfectly satisfied with that situation. I realized that Organizational Change Management is a hugely complex operation that sometimes feels not worth the trouble. More than just technical and data-centric, a huge part of Change Management relies on the human factor. By working in close proximity with operators subject to process Kaizen and implicate them into building a tool that they'd really use, I can help with Process Automation, Digitization or Improvements using homebrew solutions or existing software such as UiPath. Witnessing employees having their work quality of life improvement, and their talent used for better purpose, is something that inspires me and that I find very gratifying.",

            description: "I came to notice that Organizational Change is a high hurdle to jump, whether for SMEs or bigger companies. More than just a technical and data-centric operation, people are the main reason why a change project ends up successful or fails. More than by working in close proximity with operators subject to process Kaizen, but by making them an integral part of building a tool that they'd use, I can help with Process Automation, Digitization, or Improvements using internally-created or existing software solutions. Witnessing employees having their work quality of life improved and their talent better used is something that inspires me and that I find very gratifying.",
        },
    },


    jp: {
        title: "私の専門分野",

        subtitle: "コンピュータサイエンスとマイクロエレクトロニクスのダブルメジャーの学位を取得していることに加え、チームメンバーとして、あるいはプロジェクトリーダーとして、フランス人同士で、あるいは国際的な環境で仕事をした経験があるため、様々な技術的なプロジェクトに貢献することができます。",

        software: {
            title: "ソフトウェア工学",
            description: "スクラムでもVモデルでも、また、組み込みシステムからWebアプリまで、私の専門知識の中心は、顧客から要求を受け、分析と議論し、ソリューション設計を通し予定時間内に価値を生み出すことにあります。過剰設計でも過少設計でもない製品を産み出すことは困難ですが、私は、無駄なリソースを使わずに、需要に的確に応える革新的な製品を作りたいと思っています。",
        },

        fullstack: {
            title: "フルスタックエンジニアリング",
            description: "HTML+CSSやPHP、またはReactやNode.jsだけでなくJSSやSASSなど、定番のものから最新のものまで、様々なスタックを利用して、視覚的に美しいレスポンシブインターフェイスやRESTfulサービスを作ることができます。最近は、一般ユーザーにとってのCMSの直感的な操作性と、最新のフロントエンドライブラリやフレームワークの可能性を組み合わせるためにヘッドレスCMSに興味を持っています。",
        },

        experience: {
            title: "エクスペリエンスデザイン",
            description: "日常的なソフトウェアユーザーであると同時に、ゲーム愛好家でもある私は、UXデザインミスのために製品の可能性が台無しになってしまった例を何度も見たことがあります。直感的なUXこそが、良い製品と優れた製品を分ける鍵であると強く信じており、性能だけでなく、ギミックのない現代的な外観と感覚を持った製品を作ることを心がけています。",
        },

        process: {
            title: "プロセス改善・自動化",
            description: "これまでの経験を通じて組織改革は、中小企業でも大企業でも、ハードルの高いことだとよく分かるようになりました。技術やデータを中心とした変更以上に、変革プロジェクトの成否を左右するのは「人」です。私は、プロセス改善の対象となるオペレータの作業を分析するだけでなく、オペレータが使用するツールを共に構築することで、プロセスの自動化、デジタル化、または社内で作成したソフトウェアソリューションや既存のソフトウェアソリューションを使用したプロセス改善のお手伝いをすることができます。従業員の仕事の質が向上し、才能が活かされているのを目の当たりにすることは、私にとって刺激的であり、非常にやりがいのあることです。",
        },
    }
}

export {whatIDoContent}