import react from "../../../../assets/img/logos/logo-react-blue-1.svg";
import firebase from "../../../../assets/img/logos/logo_lockup_firebase_horizontal.svg";
import netCore from "../../../../assets/img/logos/logo_NETcore.svg";
import msSqlServer from "../../../../assets/img/logos/microsoft-sql-server.svg";
import mongoDB from "../../../../assets/img/logos/mongodb.svg";
import nodeJS from "../../../../assets/img/logos/Node.js_logo.svg";
import sass from "../../../../assets/img/logos/Sass_Logo_Color.svg";
import unity from "../../../../assets/img/logos/Unity_Technologies_logo.svg";
import vercel from "../../../../assets/img/logos/Vercel logo.svg";
import wordPress from "../../../../assets/img/logos/WordPress logo.svg";

const logos = [react, nodeJS, netCore, msSqlServer, mongoDB, sass, wordPress, unity];

const test = [nodeJS, mongoDB]

export default logos;