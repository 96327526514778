const textPrimary = "#b6b6b6";
const textSecondary = "#ececec";
const bgPrimary = "#25274D";
const bgSecondary = "#23232e";

const lateralBarStyle = {
    "@keyframes fadeIn":{
        '0%':{opacity: "0"},
        '100%':{opacity:"1"}
    },

    navBar:{
        position: "fixed",
        backgroundColor: bgPrimary,
        transition: 'all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
        zIndex:"1110",

        "&:hover $logo svg":{
            transform:"rotate(-180deg)"
        },

        boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)"
    },

    logo:{
        fontWeight: "bold",
        textTransform: "uppercase",
        marginBottom: "1rem",
        textAlign: "center",
        color: textSecondary,
        background: bgSecondary,
        fontSize: "1.5rem",
        letterSpacing: "0.3ch",
        width: "100%",

        "& svg":{
            transform: "rotate(0deg)",
            transition: 'all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s'
        }
    },

    logoText:{
        display:"inline",
        position:"absolute",
        left:"-999px",
        marginLeft: "1rem",
        transition: 'all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) 0s'
    },


    navBarNav: {
        listStyle: "none",
        padding: "0",
        margin: "0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
    },

    navItem:{
        width: "100%",
        "&:last-child":{
            marginTop: "auto"
        },

    },

    navLink:{
        display: 'flex',
        alignItems: 'center',
        height: '70px',
        color: textPrimary,
        textDecoration: 'none',
        filter: 'grayscale(100%) opacity(0.7)',
        transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
        // zIndex: "1112",

        "&:hover":{
            filter: 'grayscale(0%) opacity(1)',
            background: bgSecondary,
            color: textSecondary,
            cursor:"pointer"
        },

        "& svg":{
            width: '2rem',
            minWidth: '2rem',
            margin: '0 1.5rem',
            // zIndex: "1113"
        },
    },

    active:{
        filter: 'grayscale(0%) opacity(1)',
        "& $faPrimary":{
            color: '#ff7eee',
        },
        "& $faSecondary":{
            color: '#df49a6',
        },

    },

    linkText:{
        display: 'none',
        marginLeft: '1rem',
        // animationName:"$fadeIn",
        // animationDuration:"2s",

    },



    faPrimary:{
        color: '#ff7eee',
        transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
    },

    faSecondary:{
        color: '#df49a6',
        transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
    },

    "@media only screen and (max-width: 768px)":{
        navBar:{
            bottom: "0",
            width: "100vw",
        },

        active:{
            // borderTop: "2px solid #ff7eee",
        },

        logo:{
            display: "none"
        },

        navBarNav:{
            flexDirection: "row",
            flexWrap: "wrap",
            "& $navItem":{
                width: "25%"
            }
        },

        navLink:{
            justifyContent: "center",
            height:"50px"
        },


        main:{
            margin:"0"
        }
    },

    "@media only screen and (min-width: 768px)":{
        active:{
            borderRight: "2px solid #ff7eee",
        },
        navBar:{
            top: "0",
            width: "5rem",
            height: "100vh",

            "&:hover":{
                width: "16rem",
            },

            "&:hover $linkText":{
                display: "inline",

            },

            "&:hover $logo svg":{
                marginLeft: "11rem",
            },

            "&:hover $logoText":{
                left: "0px"
            }
        },

        linkText:{
            animationName:"$fadeIn",
            animationDuration:"0.5s",
        },
    },





};

export default lateralBarStyle;