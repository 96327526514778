import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/components/dotNavStyle";
import PropTypes from "prop-types";

import {Link} from "react-scroll";


const useStyles = makeStyles(styles);

export default function DotNav(props) {
    const classes = useStyles();
    return (
        <div>
            <nav className={classes.dotNav}>
                <ul>
                    {props.navLinks.map((navLink) => {
                        return(
                            <li className={classes.dotContainer}>
                                <Link
                                    to={navLink.id}
                                    activeClass={classes.active}
                                    spy={true}
                                    smooth={true}
                                    offset={-1}
                                    duration={500}
                                    >
                                    <span className={classes.dot}>

                                    </span>
                                    <span className={classes.dotLabel}>
                                        {navLink.label}
                                    </span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    )
}

DotNav.propTypes = {
    navLinks: PropTypes.arrayOf(PropTypes.object)
};
