const homepageContent = {
    en: {
        catchPhrase: "Hi! I'm",
        name: "Tuan-anh Bach",
        introduction1: "I'm an Engineering Master's student in Computer Science and Microelectronics, with an additional specialization in Supply Chain and Linear Optimization.",
        introduction2: "I design and build websites, web apps, native applications and electronic systems.",
        contactButton: "Get In Touch"
    },
    jp: {
        catchPhrase: "",
        name: "トナン・バーク",
        introduction1: "計算機科学と電子工学の工学修士4年生で、サプライチェーンと線形最適化を専門としています。",
        introduction2: "主にウェブサイト、ウェブサービス・アプリ、ネイティブアプリ、電子システムの設計・構築を得意としています。",
        contactButton: "お問い合わせはこちら"
    }
}

export {homepageContent};